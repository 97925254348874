import {
  Col,
  Dropdown,
  Form,
  InputNumber,
  message,
  Row,
  SelectProps,
  Space,
  Switch,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { FC, useEffect, useState } from 'react';
import { CategoryType } from '../../../dto/Category.dto';
import { CreateWatchFaceRequest } from '../../../dto/request/create-watchface-request.dto';
import { SearchFilterType } from '../../../dto/SearchFilter.dto';
import {
  ComplicationSystemSlotType,
  supportedWfAnimateMimeTypes,
  supportedWfPhotoMimeTypes,
  WatchFace,
  WatchFaceType,
  WFBottomWidgetType,
  WFComplicationType,
  WFFaceStyle,
  WFStatus,
  WFTimePosition,
  WFTimeStyle,
  WFTopWidgetType,
} from '../../../dto/WatchFace.dto';
import { useGetCategories } from '../../../services/api/category';
import { useGetSearchFilters } from '../../../services/api/searchFilters';
import {
  RATE,
  useCreateWatchFace,
  useGetDescriptionsByCategory,
  useGetTagsByCategory,
  useUpdateWatchFace,
} from '../../../services/api/watchface';
import {
  getSlots,
  getSystemSlot,
  getSystemSlots,
} from '../../../utils/complication';
import Button from '../Controls/Button';
import Select from '../Controls/Select';
import TextAreaInput from '../Controls/TextArea';
import TextInput from '../Controls/TextInput';
import ComplicationFormFields from './ComplicationFormFields';
import UploadPreview from './UploadPreview';

interface OnChangeHandler {
  (e: any): void;
}

interface CreateWatchFaceFormProps {
  onSubmit?: OnChangeHandler;
  onFinish?: OnChangeHandler;
  onChange?: OnChangeHandler;
  onCancel?: OnChangeHandler;
  onOk?: OnChangeHandler;
  watchface?: WatchFace;
}

export type FieldType = Partial<CreateWatchFaceRequest>;

const wfTypeOption: SelectProps['options'] = [
  { label: 'Photo', value: WatchFaceType.Photo },
  { label: 'Portrait', value: WatchFaceType.Portrait },
  { label: 'Animated', value: WatchFaceType.Animated },
  { label: 'Complication', value: WatchFaceType.Complication },
];

const wfTimePositionOption: SelectProps['options'] = [
  { label: 'Top', value: WFTimePosition.Top },
  { label: 'Bottom', value: WFTimePosition.Bottom },
  { label: 'Custom', value: WFTimePosition.Custom },
];

const wfTimeStyleOption: SelectProps['options'] = [
  { label: 'Classic', value: WFTimeStyle.Classic },
  { label: 'Modern', value: WFTimeStyle.Modern },
  { label: 'Photo', value: WFTimeStyle.Photo },
  { label: 'Rounded', value: WFTimeStyle.Rounded },
];

const wfStatus: SelectProps['options'] = [
  { label: 'Published', value: WFStatus.Published },
  { label: 'Unpublished', value: WFStatus.Unpublished },
];

const CreateWatchFaceForm: FC<any> = ({
  onSubmit,
  onFinish,
  onChange,
  onCancel,
  onOk,
  watchface,
  ...args
}: CreateWatchFaceFormProps) => {
  const [form] = Form.useForm();
  const wfTimePosition = Form.useWatch('wfTimePosition', form);
  const categoryId = Form.useWatch('categoryId', form);
  const wfType: WatchFaceType = Form.useWatch('wfType', form);
  const faceStyle: WFFaceStyle = Form.useWatch('faceStyle', form);

  const complicationType: WFComplicationType = Form.useWatch(
    'complicationType',
    form
  );

  const [topWidgetOptions, setTopWidgetOptions] = useState<
    SelectProps['options']
  >([]);
  const [bottomWidgetOptions, setBottomWidgetOptions] = useState<
    SelectProps['options']
  >([]);
  const [defaultPreview, setDefaultPreview] = useState<any>([]);
  const [defaultDepth, setDefaultDepth] = useState<any>([]);
  const [slots, setSlots] = useState<any>({});

  useEffect(() => {
    return () => {
      form.resetFields();
      setDefaultDepth([]);
      setDefaultPreview([]);
      setSlots({});
    };
  }, []);

  useEffect(() => {
    if (wfType && wfType !== WatchFaceType.Complication) {
      setTopWidgetOptions(
        WFTopWidgetType[wfType].map((el) => {
          return { label: el, value: el };
        })
      );
      setBottomWidgetOptions(
        WFBottomWidgetType[wfType].map((el) => {
          return { label: el, value: el };
        })
      );
    }
    if (wfType && complicationType) {
      const availableSlots = getSlots(complicationType, {
        faceStyle: watchface?.complicationsInfo?.faceStyle,
      });

      const systemSlot = getSystemSlots(complicationType, {
        faceStyle: watchface?.complicationsInfo?.faceStyle,
      });

      const temp = slots;

      availableSlots.forEach((type) => {
        if (watchface?.complicationsInfo?.[type]) {
          temp[type] = { ...watchface?.complicationsInfo?.[type] };
        } else {
          temp[type] = { ...temp[type] };
        }
      });

      systemSlot.forEach((el) => {
        const type = getSystemSlot(el);
        if (type && watchface?.complicationsInfo?.[type]) {
          temp[type] = { ...watchface?.complicationsInfo?.[type] };
        } else {
          temp[type] = { ...temp[type] };
        }
      });

      setSlots(temp);
    }
  }, [wfType, complicationType]);

  const { data: categories } = useGetCategories({
    limit: 100,
    page: 1,
    type: watchface?.category?.type,
  });

  const { data: defaultTags } = useGetTagsByCategory({ category: categoryId });
  const { data: defaultDescriptions } = useGetDescriptionsByCategory({
    category: categoryId,
  });

  const { data: searchFilters } = useGetSearchFilters({
    categoryType: CategoryType.Admin,
    type: SearchFilterType.SearchTag,
  });

  const { mutate: createWatchFace } = useCreateWatchFace({
    onSuccess: (data) => {
      form.resetFields();
      console.log(data);
      onOk?.(data);
    },
    onError: (err) => {
      const errorMessage =
        err?.response?.data?.error?.message || 'Something went wrong';
      const errorArray = err?.response?.data?.error?.errors;
      if (Array.isArray(errorArray)) {
        errorArray.forEach((element) => {
          const errors = element.constraints;
          message.error(Object.values(errors)[0]?.toString());
        });
      } else {
        message.error(errorMessage);
      }
    },
  });

  const { mutate: updateWatchFace } = useUpdateWatchFace({
    onSuccess: (data) => {
      form.resetFields();
      console.log(data);
      onOk?.(data);
    },
    onError: (err) => {
      const errorMessage =
        err?.response?.data?.error?.message || 'Something went wrong';
      const errorArray = err?.response?.data?.error?.errors;
      if (Array.isArray(errorArray)) {
        errorArray.forEach((element) => {
          const errors = element.constraints;
          message.error(Object.values(errors)[0]?.toString());
        });
      } else {
        message.error(errorMessage);
      }
    },
  });

  const handleOnFinish = async (values: any): Promise<void> => {
    const slotsInfo = {} as any;

    const slotsFile = [] as Array<any>;

    let tempIndex = 0;
    Object.entries(slots).forEach(([key, value], index) => {
      slotsInfo[key] = { ...slots[key], file: undefined };
      if (
        Object.values(ComplicationSystemSlotType).includes(
          key as ComplicationSystemSlotType
        )
      ) {
        return;
      }
      if (slots[key]?.file) {
        slotsFile.push(slots[key]?.file);
        slotsInfo[key].index = tempIndex;
        tempIndex = tempIndex + 1;
      }
      if (slots[key]?.file === null) {
        console.log('🚀 ~ Object.entries ~ key:', key);
        slotsInfo[key] = null;
      }
    });
    console.log("🚀 ~ handleOnFinish ~ values:", values, +values.fakeLikes)

    if (watchface?.id) {
      updateWatchFace({
        id: watchface?.id,
        data: {
          ...values,
          slots: slotsFile,
          position: values.position ? +values.position : undefined,
          fakeLikes: values.fakeLikes ? +values.fakeLikes : undefined,
          fakeInstalls: values.fakeInstalls ? +values.fakeInstalls : undefined,
          slotsInfo: JSON.stringify(slotsInfo),
        },
      });
    } else {
      createWatchFace({
        ...values,
        slots: slotsFile,
        position: values.position ? +values.position : undefined,
        fakeLikes: values.fakeLikes ? +values.fakeLikes : undefined,
        fakeInstalls: values.fakeInstalls ? +values.fakeInstalls : undefined,
        slotsInfo: JSON.stringify(slotsInfo),
      });
    }
  };

  const handleOnChange = (e: any): void => {
    onChange?.(e);
  };

  const handleOnCancel = (e: any): void => {
    form.resetFields();
    onCancel?.(e);
  };

  useEffect(() => {
    if (watchface === null) {
      return;
    }

    const relativeOffset = watchface?.styles?.relativeOffset
      ? (watchface.styles.relativeOffset * RATE).toFixed(16)
      : undefined;
    const wfTimeRelativeSize = watchface?.styles?.wfTimeRelativeSize
      ? (watchface.styles.wfTimeRelativeSize * RATE).toFixed(16)
      : undefined;

    const searchFilters = watchface?.searchFilters?.map(
      (searchFilter) => searchFilter.id
    );

    form.setFieldsValue({
      ...watchface,
      ...watchface?.styles,
      ...watchface?.complicationsInfo,
      wfType: watchface?.wfType,
      complicationType: watchface?.complicationsInfo?.wfType,
      relativeOffset,
      wfTimeRelativeSize,
      searchFilters,
    });

    (watchface?.styles?.previewUrl ||
      watchface?.complicationsInfo?.previewId) &&
      setDefaultPreview([
        {
          uid: '-1',
          name:
            watchface?.styles?.previewId ||
            watchface?.complicationsInfo?.previewId,
          status: 'done',
          url:
            watchface?.styles?.previewUrl ||
            watchface?.complicationsInfo?.previewUrl,
        },
      ]);
    watchface?.styles?.depthUrl &&
      setDefaultDepth([
        {
          uid: '-1',
          name: watchface?.styles?.depthId,
          status: 'done',
          url: watchface?.styles?.depthUrl,
        },
      ]);

    return () => {};
  }, [form, watchface]);

  return (
    <Form
      onChange={handleOnChange}
      onFinish={handleOnFinish}
      form={form}
      autoComplete='off'
      layout='vertical'
      {...args}
    >
      <Row>
        <Col span={24}>
          <Row gutter={[20, 20]}>
            <Col span={6}>
              <Form.Item<FieldType>
                name={'isFree'}
                label='Is Free'
                valuePropName='checked'
              >
                <Switch defaultChecked={false} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item<FieldType>
                name={'isApproved'}
                label='Is Approved'
                valuePropName='checked'
              >
                <Switch defaultChecked={false} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<FieldType> name={'position'} label='Position'>
                <TextInput placeholder='Watchface position' type='number' />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row gutter={[20, 20]}>
            <Col span={12}>
            <Form.Item<FieldType> name={'fakeLikes'} label='Fake Likes'>
                <TextInput placeholder='Watchface fake Likes' type='number' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<FieldType> name={'fakeInstalls'} label='Fake Installs'>
                <TextInput placeholder='Watchface fake installs' type='number' />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Form.Item<FieldType>
                name={'status'}
                label='Watchface Status'
                rules={[
                  {
                    required: true,
                    message: 'Please select watchface status!',
                  },
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder='WatchFace Status'
                  options={wfStatus}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<FieldType>
                name={'wfType'}
                label='Watchface Type'
                rules={[
                  { required: true, message: 'Please select watchface type!' },
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder='WatchFace Type'
                  options={wfTypeOption}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col span={6}>
          <Form.Item<FieldType>
            name='preview'
            label='Preview'
            rules={[
              {
                required: !defaultPreview?.length,
                message: 'Please upload watchface preview!',
              },
            ]}
          >
            <UploadPreview
              name='preview'
              supportType={
                wfType === WatchFaceType.Animated
                  ? supportedWfAnimateMimeTypes
                  : supportedWfPhotoMimeTypes
              }
              defaultFileList={defaultPreview}
            />
          </Form.Item>
        </Col>
        {[WatchFaceType.Portrait].includes(wfType) && (
          <Col span={6}>
            <Form.Item<FieldType> name='depth' label='Dsepth'>
              <UploadPreview
                name='depth'
                supportType={supportedWfPhotoMimeTypes}
                defaultFileList={defaultDepth}
              />
            </Form.Item>
          </Col>
        )}

        <Col span={24}>
          <Form.Item<FieldType>
            name={'name'}
            label='Name'
            rules={[
              { required: true, message: 'Please input watchface name!' },
            ]}
          >
            <TextInput placeholder='WatchFace Name' />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item<FieldType>
            name={'description'}
            label='Description'
            rules={[
              {
                required: true,
                message: 'Please input watchface description!',
              },
            ]}
          >
            <TextAreaInput
              autoSize={{ minRows: 2, maxRows: 6 }}
              maxLength={256}
              placeholder='WatchFace Description'
            />
          </Form.Item>
        </Col>

        {Boolean(defaultDescriptions?.length) && (
          <Col span={24} style={{ marginBottom: '24px' }}>
            <Dropdown
              menu={{
                items: defaultDescriptions?.map((el) => ({
                  label: el,
                  key: el,
                })),
                onClick: ({ key }) => form.setFieldValue('description', key),
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Description Default Values
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </Col>
        )}

        <Col span={24}>
          <Form.Item<FieldType>
            name={'categoryId'}
            label='Category'
            rules={[
              { required: true, message: 'Please select watchface category!' },
            ]}
          >
            <Select
              // showSearch
              style={{ width: '100%' }}
              placeholder='WatchFace Category'
              options={categories?.data?.map((el) => {
                return { label: `${el.name} - ${el.type}`, value: el.id };
              })}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item<FieldType>
            name={'tags'}
            label='Tags'
            rules={[
              { required: true, message: 'Please input watchface tags!' },
              {
                type: 'array',
                min: 1,
                message: 'Please input at least one tag!',
              },
            ]}
          >
            <Select
              mode='tags'
              style={{ width: '100%' }}
              placeholder='Tags'
              options={defaultTags?.map((el) => {
                return { label: el, value: el };
              })}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item<FieldType> name={'searchFilters'} label='Search Filters'>
            <Select
              mode='multiple'
              allowClear
              style={{ width: '100%' }}
              placeholder='Search Filters'
              options={searchFilters?.map((el) => ({
                label: el.name,
                value: el.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row gutter={[20, 20]}>
            {[
              WatchFaceType.Portrait,
              WatchFaceType.Photo,
              WatchFaceType.Portrait,
              WatchFaceType.Animated,
            ].includes(wfType) && (
              <Col span={12}>
                <Form.Item<FieldType>
                  name={'wfTimePosition'}
                  label='Time Position'
                  rules={[
                    {
                      required: true,
                      message: 'Please select watchface time position!',
                    },
                  ]}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder='WatchFace Time Position'
                    options={wfTimePositionOption}
                  />
                </Form.Item>
              </Col>
            )}

            {wfTimePosition === WFTimePosition.Custom && (
              <Col span={12}>
                <Form.Item<FieldType>
                  name={'relativeOffset'}
                  label='Time Offset'
                  rules={[
                    {
                      required: wfTimePosition === WFTimePosition.Custom,
                      message: 'Please select watchface time position!',
                    },
                  ]}
                >
                  <InputNumber
                    min={1}
                    max={RATE}
                    style={{ width: '100%' }}
                    placeholder='WatchFace Relative Offset'
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Col>

        <Col span={24}>
          <Row gutter={[20, 20]}>
            {[WatchFaceType.Portrait, WatchFaceType.Animated].includes(
              wfType
            ) && (
              <Col span={12}>
                <Form.Item<FieldType>
                  name={'topWFWidgetType'}
                  label='Top Widget'
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder='WatchFace Top Widget'
                    options={topWidgetOptions}
                  />
                </Form.Item>
              </Col>
            )}

            {[WatchFaceType.Portrait, WatchFaceType.Animated].includes(
              wfType
            ) && (
              <Col span={12}>
                <Form.Item<FieldType>
                  name={'bottomWFWidgetType'}
                  label='Bottom Widget'
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder='WatchFace Bottom Widget'
                    options={bottomWidgetOptions}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Col>

        <Col span={24}>
          <Row gutter={[20, 20]}>
            {[WatchFaceType.Portrait, WatchFaceType.Animated].includes(
              wfType
            ) && (
              <Col span={12}>
                <Form.Item<FieldType> name={'wfTimeStyle'} label='Time Style'>
                  <Select
                    style={{ width: '100%' }}
                    placeholder='WatchFace Time Style'
                    options={wfTimeStyleOption}
                  />
                </Form.Item>
              </Col>
            )}

            {[WatchFaceType.Portrait].includes(wfType) && (
              <Col span={12}>
                <Form.Item<FieldType>
                  name={'wfTimeRelativeSize'}
                  label='Time Relative Size'
                >
                  <InputNumber
                    min={1}
                    max={RATE}
                    style={{ width: '100%' }}
                    placeholder='WatchFace Time Relative Size'
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Col>

        {[WatchFaceType.Complication].includes(wfType) && (
          <Col span={24}>
            <ComplicationFormFields
              form={form}
              setSlots={setSlots}
              slots={slots}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col span={24} style={{ display: 'flex', gap: '10px' }}>
          <Button htmlType='submit'>
            {watchface?.id ? 'Update' : 'Create'}
          </Button>
          <Button onClick={handleOnCancel}>Cancel</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CreateWatchFaceForm;
