import { Category } from "./Category.dto";
import { ReportWithUser } from "./Report.dto";
import { SearchFilter } from "./SearchFilter.dto";

export enum WatchFaceType {
  Photo = 'photo',
  Portrait = 'portrait',
  Animated = 'animated',
  Complication = 'complication',
}

export const WatchFaceTypeOptions = [
  {
      text: 'Photo',
      value: WatchFaceType.Photo,
  },
  {
      text: 'Portrait',
      value: WatchFaceType.Portrait,
  },
  {
      text: 'Animated',
      value: WatchFaceType.Animated,
  },
  {
    text: 'Complication',
    value: WatchFaceType.Complication,
},
]

export enum WFTimeStyle {
  Photo = 'photo',
  Classic = 'classic',
  Modern = 'modern',
  Rounded = 'rounded',
}

export enum WFTimePosition {
  Top = 'top',
  Bottom = 'bottom',
  Custom = 'custom',
}

export enum WFStatus {
  Published = 'published',
  Unpublished = 'unpublished',
  Suspected = 'suspected',
  Banned = 'banned',
}

export const WFStatusOptions = [
  {
      text: 'Published',
      value: WFStatus.Published,
  },
  {
      text: 'Unpublished',
      value: WFStatus.Unpublished,
  },
  {
      text: 'Suspected',
      value: WFStatus.Suspected,
  },
  {
    text: 'Banned',
    value: WFStatus.Banned,
},
]

export enum WFSeries {
  Series2AndLater = 'wf2andLower',
  Series3 = 'wf3',
  Series4 = 'wf4',
  Series5 = 'wf5',
  Series6 = 'wf6',
  Series7 = 'wf7',
  Series8 = 'wf8',
  Series9 = 'wf9',
  SeriesSE = 'wfSE',
  SeriesSE2 = 'wfSE2',
  SeriesUltra = 'wfULTRA',
  SeriesUltra2 = 'wfULTRA2'
}

export enum WFWidgetType {
  Off = 'off',

  TodaysDate = 'todaysDate',
  YourSchedule = 'yourSchedule',

  Compass = 'compass',
  CompassElevation = 'compassElevation',
  Elevation = 'elevation',

  Timer = 'timer',
  Timer1min = 'timer1min',
  Timer3min = 'timer3min',
  Timer5min = 'timer5min',
  Timer10min = 'timer10min',
  Timer15min = 'timer15min',
  Timer30min = 'timer30min',
  Timer1hr = 'timer1hr',
  Timer2hr = 'timer2hr',

  Wind = 'wind',
  Rain = 'rain',
  Weather = 'weather',
  UvIndex = 'uvIndex',

  Alarm = 'alarm',
  SunRiseSet = 'sunRiseSet',
  Activity = 'activity',
  MoonPhase = 'moonPhase',
  AudioBooks = 'audioBooks',
  Messages = 'messages',
  Music = 'music',
  Podcasts = 'podcasts',
  Workout = 'workout',
  Battery = 'battery',
  HeartRate = 'heartRate',
  SoundLevels = 'soundLevels',
  Reminders = 'reminders',
  Stopwatch = 'stopwatch',
}

export const WFTopWidgetType = {
  [WatchFaceType.Photo]: [
    WFWidgetType.Off,
    WFWidgetType.Alarm,
    WFWidgetType.TodaysDate,
    WFWidgetType.YourSchedule,
    WFWidgetType.Compass,
    WFWidgetType.Elevation,
    WFWidgetType.Battery,
    WFWidgetType.HeartRate,
    WFWidgetType.SoundLevels,
    WFWidgetType.Reminders,
    WFWidgetType.Stopwatch,
    WFWidgetType.Timer,
    WFWidgetType.Timer1min,
    WFWidgetType.Timer3min,
    WFWidgetType.Timer5min,
    WFWidgetType.Timer10min,
    WFWidgetType.Timer15min,
    WFWidgetType.Timer30min,
    WFWidgetType.Timer1hr,
    WFWidgetType.Timer2hr,
    WFWidgetType.Weather,
    WFWidgetType.Wind,
    WFWidgetType.Rain,
    WFWidgetType.UvIndex,
    WFWidgetType.SunRiseSet,
  ],
  [WatchFaceType.Animated]: [
    WFWidgetType.Off,
    WFWidgetType.Alarm,
    WFWidgetType.TodaysDate,
    WFWidgetType.YourSchedule,
    WFWidgetType.Compass,
    WFWidgetType.Elevation,
    WFWidgetType.HeartRate,
    WFWidgetType.Reminders,
    WFWidgetType.Stopwatch,
    WFWidgetType.Timer,
    WFWidgetType.Timer1min,
    WFWidgetType.Timer3min,
    WFWidgetType.Timer5min,
    WFWidgetType.Timer10min,
    WFWidgetType.Timer15min,
    WFWidgetType.Timer30min,
    WFWidgetType.Timer1hr,
    WFWidgetType.Timer2hr,
    WFWidgetType.Weather,
    WFWidgetType.Wind,
    WFWidgetType.Rain,
    WFWidgetType.UvIndex,
    WFWidgetType.Battery,
    WFWidgetType.SoundLevels,
    WFWidgetType.SunRiseSet,
  ],
  [WatchFaceType.Portrait]: [
    WFWidgetType.Off,
    WFWidgetType.TodaysDate,
  ],
}

export const WFBottomWidgetType = {
  [WatchFaceType.Photo]: [
    WFWidgetType.Off,
    WFWidgetType.Activity,
    WFWidgetType.Alarm,
    WFWidgetType.MoonPhase,
    WFWidgetType.AudioBooks,
    WFWidgetType.TodaysDate,
    WFWidgetType.YourSchedule,
    WFWidgetType.Compass,
    WFWidgetType.Elevation,
    WFWidgetType.CompassElevation,
    WFWidgetType.Battery,
    WFWidgetType.HeartRate,
    WFWidgetType.Messages,
    WFWidgetType.Music,
    WFWidgetType.SoundLevels,
    WFWidgetType.Podcasts,
    WFWidgetType.Reminders,
    WFWidgetType.Stopwatch,
    WFWidgetType.Timer,
    WFWidgetType.Timer1min,
    WFWidgetType.Timer3min,
    WFWidgetType.Timer5min,
    WFWidgetType.Timer10min,
    WFWidgetType.Timer15min,
    WFWidgetType.Timer30min,
    WFWidgetType.Timer1hr,
    WFWidgetType.Timer2hr,
    WFWidgetType.Weather,
    WFWidgetType.Wind,
    WFWidgetType.Rain,
    WFWidgetType.UvIndex,
    WFWidgetType.Workout,
    WFWidgetType.SunRiseSet,
  ],
  [WatchFaceType.Animated]: [
    WFWidgetType.Off,
    WFWidgetType.Activity,
    WFWidgetType.Alarm,
    WFWidgetType.MoonPhase,
    WFWidgetType.AudioBooks,
    WFWidgetType.TodaysDate,
    WFWidgetType.YourSchedule,
    WFWidgetType.Compass,
    WFWidgetType.Elevation,
    WFWidgetType.CompassElevation,
    WFWidgetType.Battery,
    WFWidgetType.HeartRate,
    WFWidgetType.Messages,
    WFWidgetType.Music,
    WFWidgetType.SoundLevels,
    WFWidgetType.Podcasts,
    WFWidgetType.Reminders,
    WFWidgetType.Stopwatch,
    WFWidgetType.Timer,
    WFWidgetType.Timer1min,
    WFWidgetType.Timer3min,
    WFWidgetType.Timer5min,
    WFWidgetType.Timer10min,
    WFWidgetType.Timer15min,
    WFWidgetType.Timer30min,
    WFWidgetType.Timer1hr,
    WFWidgetType.Timer2hr,
    WFWidgetType.Weather,
    WFWidgetType.Wind,
    WFWidgetType.Rain,
    WFWidgetType.UvIndex,
    WFWidgetType.Workout,
    WFWidgetType.SunRiseSet,
  ],
  [WatchFaceType.Portrait]: [
    WFWidgetType.Off,
    WFWidgetType.Activity,
    WFWidgetType.Alarm,
    WFWidgetType.MoonPhase,
    WFWidgetType.AudioBooks,
    WFWidgetType.TodaysDate,
    WFWidgetType.YourSchedule,
    WFWidgetType.Compass,
    WFWidgetType.CompassElevation,
    WFWidgetType.Elevation,
    WFWidgetType.Battery,
    WFWidgetType.HeartRate,
    WFWidgetType.Messages,
    WFWidgetType.Music,
    WFWidgetType.SoundLevels,
    WFWidgetType.Podcasts,
    WFWidgetType.Reminders,
    WFWidgetType.Timer,
    WFWidgetType.Timer1min,
    WFWidgetType.Timer3min,
    WFWidgetType.Timer5min,
    WFWidgetType.Timer10min,
    WFWidgetType.Timer15min,
    WFWidgetType.Timer30min,
    WFWidgetType.Timer1hr,
    WFWidgetType.Timer2hr,
    WFWidgetType.Weather,
    WFWidgetType.Wind,
    WFWidgetType.Rain,
    WFWidgetType.UvIndex,
    WFWidgetType.Workout,
    WFWidgetType.SunRiseSet,
  ],
}

export const supportedWfPhotoMimeTypes = [
  'image/png',
  'image/jpeg',
  'image/heic',
];

export const supportedWfAnimateMimeTypes = [
  'image/gif',
  'video/quicktime',
  'video/mp4'
];

export enum WFComplicationType {
  Infograph = 'infograph',
  California = 'california',
  Meridian = 'meridian',
  InfographModular = 'infographModular',
  InfographModularColored = 'infographModularColored', // iOS 16 Only
  XLarge = 'xLarge',
  XLargeColored = 'xLargeColored', // iOS 16 Only
  ModularCompact = 'modularCompact',
  ModularCompactColored = 'modularCompactColored', // iOS 16 Only
  ModularDuo = 'modularDuo', // SE7 and hight (no SE & SE2)
}

export enum ComplicationSlotType {
  SubdialTop = 'subdialTop',
  SubdialLeft = 'subdialLeft',
  SubdialRight = 'subdialRight',
  SubdialBottom = 'subdialBottom',
  Bezel = 'bezel',
  Slot1 = 'slot1',
  Slot2 = 'slot2',
  Slot3 = 'slot3',
  TopLeft = 'topLeft',
  BottomLeft = 'bottomLeft',
  BottomCenter = 'bottomCenter',
  BottomRight = 'bottomRight',
  Top = 'top',
  Center = 'center',
  Bottom = 'bottom',
}

export enum ComplicationSystemSlotType {
  SystemSubdialTop = 'systemSubdialTop',
  SystemSubdialLeft = 'systemSubdialLeft',
  SystemSubdialRight = 'systemSubdialRight',
  SystemSubdialBottom = 'systemSubdialBottom',
  SystemBezel = 'systemBezel',
  SystemSlot1 = 'systemSlot1',
  SystemSlot2 = 'systemSlot2',
  SystemSlot3 = 'systemSlot3',
  SystemTopLeft = 'systemTopLeft',
  SystemBottomLeft = 'systemBottomLeft',
  SystemBottomCenter = 'systemBottomCenter',
  SystemBottomRight = 'systemBottomRight',
  SystemTop = 'systemTop',
  SystemCenter = 'systemCenter',
  SystemBottom = 'systemBottom',
}

export enum CustomColorType {
  Multicolor = 'multicolor',
  StandardRed = 'standard.red',
  StandardOrange = 'standard.orange',
  StandardLightOrange = "standard.lightOrange",
  StandardGreen = 'standard.green',
  StandardLightGreen = "standard.lightGreen",
  StandardYellow = "standard.yellow",
  StandardLightYellow = "standard.lightYellow",
  StandardBlue = 'standard.blue',
  StandardLightBlue = 'standard.lightBlue',
  StandardNavy = 'standard.navy',
  StandardPurple = 'standard.purple',
  StandardLightPurple = 'standard.lightPurple',
  StandardPink = 'standard.pink',
  StandardLightPink = 'standard.lightPink',
  StandardPlum = 'standard.plum',
  StandardStone = 'standard.stone',
  StandardGray = 'standard.fray',
  StandardWarmGray = 'standard.warmGray',
  StandardCream = 'standard.cream',
  GossamerColor1 = 'gossamer.color1', // iOS 16 Only 
  GossamerColor2 = 'gossamer.color2', // iOS 16 Only 
  GossamerColor3 = 'gossamer.color3', // iOS 16 Only 
  GossamerColor4 = 'gossamer.color4', // IOS 16 Only 
  GossamerColor5 = 'gossamer.color5', // IOS 16 Only 
  GossamerColor6 = 'gossamer.color6', // iOS 16 Only 
  GossamerColor7 = 'gossamer.color7', // iOS 16 Only 
  GossamerColor8 = 'gossamer.color8', // iOS 16 Only

  SeasonSuccelent = 'seasons.fall2022.succulent', // iOS 16 Only 
  SeasonSunglow = 'seasons.fall2022.sunglow', // iOS 16 Only 
  SeasonBrightGreen = 'seasons.spring2022.green3', // iOS 16 Only 
  SeasonFlamingo = 'seasons.spring2022.pink6t', // iOS 16 Only

  SeasonAbyssBlue = 'seasons.fall2021.blue11',
  SeasonElectricOrange = 'ElectricOrange',
  SeasonCloudBlue = 'CloudBlue',
  SeasonWhite = 'seasons.spring2015.white', // infographModulrar Only
}

export enum BackgroundColorType {
  WhiteMeridian = "style 2",
  BlackMeridian = "style 1",

  WhiteInfograph = "white",
  BlackInfograph = "whistler black",

  BlackModularCompact = "whistler black",

  WhiteCalifornia = "style 1",
  BlackCalifornia = "style 3",
  Rainbow = "style 10",
  Ceramic = "style 8",
  Gold = "style 9",
  NavyBlue = "style 4",
  Ivory = "style 2",
  PalePink = "style 5",
  Evergreen = "style 11",
  Mango = "style 12",
  Slate = "style 13",
  Watermelon = "style 15",
  Red = "standard.red",
  Orange = "standard.orange",
  Green = "standard.green",
  LightPink = "standard lightPink",
}

export enum WFStyleType {
  Digital = 'digital',
  Analog = 'analog',
}

export enum WFFaceStyle {
  Circular = 'circular',
  Fullscreen = 'fullscreen',
}

export enum WFFaceType {
  Stripes = "style 1",
  Roman = "style 2",
  California = "style 3",
  Arabic = "style 4",
  Arabicindic = "style 5",
  Devanagari = "style 6",
  Chinese = "style 8",
}

export enum WFFamilyType {
  Circular = 'circular',
  Rectangular = 'rectangular',
}

export enum WFInformationType {
  Date = 'date',
}
export interface WatchFace {
  readonly id: string;
  readonly wfType: WatchFaceType;
  readonly status: WFStatus;
  readonly category: Category;
  readonly name: string;

  readonly isFree: boolean;
  readonly isApproved: boolean;

  readonly isLiked: boolean;
  readonly isReported: boolean;

  readonly installsCount: number;
  readonly likesCount: number;
  readonly reportsCount: number;

  readonly minIOSVersion: Array<number>;

  readonly position: number;
  readonly fakeLikes: number;
  readonly fakeInstalls: number;


  readonly styles: any;
  readonly complicationsInfo: any;
  readonly tags: Array<string>;
  readonly searchFilters: Array<SearchFilter>

}

export interface WatchFaceWithReport extends WatchFace { 
  readonly reports: Array<ReportWithUser>
}

export interface SystemComplicationPreviewModel {
  readonly familyType: WFFamilyType;
  readonly informationType: WFInformationType;

}