import { Form, message, SelectProps } from 'antd';
import { FC, useEffect } from 'react';
import { Category, CategoryType } from '../../../dto/Category.dto';
import { CreateCategory } from '../../../dto/request/categories-request.dto';
import {
  useCreateCategory,
  useUpdateCategory,
} from '../../../services/api/category';
import Button from '../Controls/Button';
import Select from '../Controls/Select';
import TextInput from '../Controls/TextInput';

interface OnChangeHandler {
  (e: any): void;
}

interface CreateWatchFaceFormProps {
  onSubmit?: OnChangeHandler;
  onFinish?: OnChangeHandler;
  onChange?: OnChangeHandler;
  onCancel?: OnChangeHandler;
  onOk?: OnChangeHandler;
  category?: Category;
}

export type FieldType = Partial<CreateCategory>;

const categoryTypeOption: SelectProps['options'] = [
  { label: 'Admin', value: CategoryType.Admin },
  { label: 'Customer', value: CategoryType.Customer },
];
const CreateCategoryForm: FC<any> = ({
  onSubmit,
  onFinish,
  onChange,
  onCancel,
  onOk,
  category,
  ...args
}: CreateWatchFaceFormProps) => {
  const [form] = Form.useForm();

  const { mutate: createCategory } = useCreateCategory({
    onSuccess: (data) => {
      form.resetFields();
      onOk?.(data);
    },
    onError: (err) => {
      const errorMessage =
        err?.response?.data?.error?.message || 'Something went wrong';
      const errorArray = err?.response?.data?.error?.errors;
      if (Array.isArray(errorArray)) {
        errorArray.forEach((element) => {
          const errors = element.constraints;
          message.error(Object.values(errors)[0]?.toString());
        });
      } else {
        message.error(errorMessage);
      }
    },
  });

  const { mutate: updateCategory } = useUpdateCategory({
    onSuccess: (data) => {
      form.resetFields();
      onOk?.(data);
    },
    onError: (err) => {
      const errorMessage =
        err?.response?.data?.error?.message || 'Something went wrong';
      const errorArray = err?.response?.data?.error?.errors;
      if (Array.isArray(errorArray)) {
        errorArray.forEach((element) => {
          const errors = element.constraints;
          message.error(Object.values(errors)[0]?.toString());
        });
      } else {
        message.error(errorMessage);
      }
    },
  });

  const handleOnFinish = async (values: any): Promise<void> => {
    const body = {
      ...values,
      position: +values.position,
    };
    if (category?.id) {
      updateCategory({ id: category.id, data: body });
    } else {
      createCategory(body);
    }
  };

  const handleOnChange = (e: any): void => {
    onChange?.(e);
  };

  const handleOnCancel = (e: any): void => {
    form.resetFields();
    onCancel?.(e);
  };

  useEffect(() => {
    if (category === null) {
      form.resetFields();
      
      return;
    }

    form.setFieldsValue({
      ...category,
    });
  }, [form, category]);

  return (
    <Form
      onChange={handleOnChange}
      onFinish={handleOnFinish}
      form={form}
      initialValues={category}
      autoComplete='off'
      layout='vertical'
      {...args}
    >
      <Form.Item<FieldType>
        name={'name'}
        label='Name'
        rules={[{ required: true, message: 'Please input category name!' }]}
      >
        <TextInput placeholder='Category name' />
      </Form.Item>

      <Form.Item<FieldType>
        name={'type'}
        label='Type'
        rules={[{ required: true, message: 'Please select category type!' }]}
      >
        <Select
          style={{ width: '100%' }}
          placeholder='Category type'
          options={categoryTypeOption}
        />
      </Form.Item>

      <Form.Item<FieldType> name={'position'} label='Position'>
        <TextInput placeholder='Category position' type='number' />
      </Form.Item>

      <Button htmlType='submit'>{category?.id ? 'Update' : 'Create'}</Button>
      <Button onClick={handleOnCancel}>Cancel</Button>
    </Form>
  );
};

export default CreateCategoryForm;
