import { FC } from 'react';

const ColumnImage: FC<any> = ({ src, alt, type, depthUrl }) => {
  console.log('🚀 ~ depth:', depthUrl);
  return (
    <>
      <div className='w-[6rem] h-auto rbg-slate-400 flex items-center justify-center text-sm'>
        {depthUrl?.length && (
          <img
            src={depthUrl}
            alt={alt}
            className={'w-[6rem] h-auto object-cover absolute'}
          />
        )}
        {type === 'img' ? (
          <img
            src={src}
            alt={alt}
            className={'w-[6rem] h-auto object-cover '}
          />
        ) : (
          <video loop autoPlay muted>
            <source src={src} />
          </video>
        )}
      </div>
    </>
  );
};

export default ColumnImage;
