import { Category } from "./Category.dto";

export interface SearchFilter {
    readonly id: string;
    readonly name: string;
    readonly position: number;
    readonly type: SearchFilterType;
    readonly categoryType: CategoryType;
    readonly isActive: boolean;
    readonly category: Category;
}

export enum CategoryType {
    All = 'all',
    Admin = 'admin',
    Customer = 'customer',
}
export enum SearchFilterType {
    New = 'new',
    Top = 'top',
    Trending = 'trending',
    Category = 'category',
    SearchTag = 'search_tag',
}

