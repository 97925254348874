import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyDQkP5kI5uIvoZC8XBhTCQcnYtxNuYoLMI",
  authDomain: "watch-faces-club-test.firebaseapp.com",
  projectId: "watch-faces-club-test",
  storageBucket: "watch-faces-club-test.appspot.com",
  messagingSenderId: "589012771089",
  appId: "1:589012771089:web:e7739788cbfbfa41896cb0",
  measurementId: "G-5YVQEXZCPG"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyCflK5RZLPXhcjvs-5Ddx-bYHupMjncWCQ",
//   authDomain: "cocktail-database-a5b02.firebaseapp.com",
//   databaseURL: "https://cocktail-database-a5b02.firebaseio.com",
//   projectId: "cocktail-database-a5b02",
//   storageBucket: "cocktail-database-a5b02.appspot.com",
//   messagingSenderId: "173371315825",
//   appId: "1:173371315825:web:0d7873874ea202a7002dd0",
//   measurementId: "G-QE5HP4LE4C"
// };

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export default app;