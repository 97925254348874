import { Layout } from 'antd';
import { FC, useState } from 'react';
import SideMenu from '../SideMenu/SideMenu';
import HeaderComponent from '../Header/Header'
const { Header, Sider, Content } = Layout;

const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#fff',
  height: 64,
  paddingInline: 50,
  lineHeight: '64px',
  backgroundColor: '#81D4FA',
};

const contentStyle: React.CSSProperties = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: '#FFFFFF',
};

const siderStyle: React.CSSProperties = {
  textAlign: 'center',
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: '#81D4FA',
};

const DefaultLayout: FC<any> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleCloseSider = () => {
    setCollapsed(true);
  };

  const handleOpenSider = () => {
    setCollapsed(false);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        style={siderStyle}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        collapsedWidth={0}
        trigger={null}
      >
        <SideMenu onClose={handleCloseSider}/>
      </Sider>
      <Layout>
        <Header style={headerStyle}>
        <HeaderComponent isOpen={collapsed} onOpen={handleOpenSider}/>
        </Header>
        <Content style={contentStyle}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
