import { QueryOptions, useMutation, useQuery, useQueryClient } from "react-query";
import api from ".";
import { ReportStatus } from "../../dto/Report.dto";
import { ReportsPaginationOptions, WatchFacesWithReportsResponse } from "../../dto/request/reports-watchfaces-request.dto";
import { ApiServiceErr, MutOpt } from "../../types/api.types";

export const useGetWatchFacesWithReports = (
    { limit = 10, page = 1, type, categoryType, status, sortOrder, sortField }: ReportsPaginationOptions,
    opt?: QueryOptions<WatchFacesWithReportsResponse>
) => {

    return useQuery<WatchFacesWithReportsResponse, ApiServiceErr>(
        ['watchfacesWithReports', limit, page, type, categoryType, status, sortOrder, sortField],
        async () => {
            const response = await api.get<WatchFacesWithReportsResponse>(
                `/admin/watchface/report`,
                {
                    params: {
                        limit,
                        page,
                        type,
                        categoryType,
                        status,
                        sortOrder, sortField
                    }
                },
            );
            return response;
        },
        opt,
    );
};

export const useReadReport = (opt?: MutOpt<any>) => {
    const queryClient = useQueryClient();

    return useMutation<any, ApiServiceErr, string>(async (id) => {

        const response = await api.post(`/admin/watchface/report/${id}`, {status: ReportStatus.Read});
        return response;
    }, {
        ...opt,
        onSuccess: (data: any, id: string, context: unknown) => {
            queryClient.invalidateQueries("watchfacesWithReports");
            opt?.onSuccess?.(data, id, context);
        }
    });
}

export const useApproveWatchFace = (opt?: MutOpt<any>) => {
    const queryClient = useQueryClient();

    return useMutation<any, ApiServiceErr, string>(async (id) => {
    console.log("🚀 ~ returnuseMutation<any,ApiServiceErr,string> ~ id:", id)

        const response = await api.post(`/admin/watchface/approve/${id}`, {});
        return response;
    }, {
        ...opt,
        onSuccess: (data: any, id: string, context: unknown) => {
            queryClient.invalidateQueries("watchfacesWithReports");
            opt?.onSuccess?.(data, id, context);
        }
    });
}

export const useDeleteReport = (opt?: MutOpt<any>) => {
    const queryClient = useQueryClient();

    return useMutation<any, ApiServiceErr, string>(async (id) => {

        const response = await api.delete(`/admin/watchface/report/${id}`);
        return response;
    }, {
        ...opt,
        onSuccess: (data: any, id: string, context: unknown) => {
            queryClient.invalidateQueries("watchfacesWithReports");
            opt?.onSuccess?.(data, id, context);
        }
    });
}