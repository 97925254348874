import React from 'react';
import { User } from '../../dto/User.dto';

interface ExpandComponentProps {
  user: User;
}

const ExpandComponent: React.FC<ExpandComponentProps> = ({
  user,
  ...args
}: ExpandComponentProps) => {
  return (
    <>
      <div className='flex flex-col'>
        <span>ID: {user.id}</span>
        <span>Firebase ID: {user.firebaseId}</span>
        <br></br>
        <span>Bio: {user.bio}</span>
        <span>Twitter: {user.twitter}</span>
        <span>Instagram: {user.instagram}</span>
        <span>Facebook: {user.facebook}</span>
        <span>Dribble: {user.dribble}</span>
        <span>Snapchat: {user.snapchat}</span>
        <br></br>
        <span>Created At: {user.createdAt}</span>
        <span>Updated At: {user.updatedAt}</span>
      </div>
    </>
  );
};

export default ExpandComponent;
