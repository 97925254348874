import { Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import Button from '../components/Forms/Controls/Button';
import DefaultLayout from '../components/Layouts/DefaultLayout';
import Modal from '../components/Modal';
import CreateSearchFilterModal from '../components/Modals/CreateSearchFilterModal';
import {
  CategoryType,
  SearchFilter,
  SearchFilterType,
} from '../dto/SearchFilter.dto';
import { useDeleteSearchFilter, useGetSearchFilters } from '../services/api/searchFilters';

interface DataType {
  key: string;
  name: string;
  categoryType: CategoryType;
  type: SearchFilterType;
  position: number;
}

const SearchFiltersPage: React.FC = () => {
  const { Column } = Table;

  const [type, setType] = useState<SearchFilterType | undefined>();
  const [categoryType, setCategoryType] = useState<CategoryType | undefined>(
    CategoryType.Admin
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedSearchFilter, setSelectedSearchFilter] =
    useState<SearchFilter | null>();

  const [dataSource, setDataSource] = useState<DataType[]>([]);

  const { data: serachFilters, isLoading } = useGetSearchFilters({
    type: type,
    categoryType: categoryType,
  });

  const { mutateAsync: deleteSearchFilter } = useDeleteSearchFilter();

  const handleAddSerachFilters = () => {
    setSelectedSearchFilter(null);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!isLoading && serachFilters) {
      setDataSource(
        serachFilters?.map((el, index) => {
          return {
            key: el.id,
            name: el.name,
            categoryType: el.categoryType,
            type: el.type,
            position: el.position,
          };
        })
      );
    }
  }, [isLoading, serachFilters]);

  return (
    <>
      <DefaultLayout>
        <main>
          <div>
            <h2 className='text-black'>Search Filters Page</h2>
            <Button onClick={handleAddSerachFilters}>Add</Button>
          </div>

          <div>
            <Table
              dataSource={dataSource}
              loading={isLoading}
              pagination={false}
              onChange={(_, filter) => {
                setType(
                  filter.type?.[0]
                    ? (filter.type[0] as SearchFilterType)
                    : undefined
                );
                setCategoryType(
                  filter.categoryType?.[0]
                    ? (filter.categoryType[0] as CategoryType)
                    : undefined
                );
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    setSelectedSearchFilter(
                      serachFilters?.find((el) => el.id === record.key)
                    );
                    setIsModalOpen(true);
                  },
                };
              }}
            >
              <Column title='Id' dataIndex='key' key='key' width={'30%'} />
              <Column
                title='Position'
                dataIndex='position'
                key='position'
                width={'10%'}
              />
              <Column title='Name' dataIndex='name' key='name' width={'30%'} />
              <Column
                title='Category Type'
                dataIndex='categoryType'
                key='categoryType'
                width={'30%'}
                defaultFilteredValue={[CategoryType.Admin]}
                filterResetToDefaultFilteredValue
                filters={[
                  {
                    text: 'All',
                    value: CategoryType.All,
                  },
                  {
                    text: 'Admin',
                    value: CategoryType.Admin,
                  },
                  {
                    text: 'Customer',
                    value: CategoryType.Customer,
                  },
                ]}
                filterMultiple={false}
              />
              <Column
                title='Type'
                dataIndex='type'
                key='type'
                width={'30%'}
                filters={[
                  {
                    text: 'Category',
                    value: SearchFilterType.Category,
                  },
                  {
                    text: 'Search Tag',
                    value: SearchFilterType.SearchTag,
                  },
                  {
                    text: 'New',
                    value: SearchFilterType.New,
                  },
                  {
                    text: 'Top',
                    value: SearchFilterType.Top,
                  },
                  {
                    text: 'Trending',
                    value: SearchFilterType.Trending,
                  },
                ]}
                filterMultiple={false}
              />
              <Column
                title='Action'
                key='action'
                render={(_: any, record: DataType) => (
                  <Space size='middle'>
                    <Button
                      onClick={(event) => {
                        event.stopPropagation();
                        setSelectedSearchFilter(serachFilters?.find((el) => el.id === record.key));
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      Delete{' '}
                    </Button>
                  </Space>
                )}
              />
            </Table>
          </div>
        </main>
      </DefaultLayout>
      <CreateSearchFilterModal
        setIsOpen={setIsModalOpen}
        isOpen={isModalOpen}
        searchFilter={selectedSearchFilter}
      />
      <Modal
        title='Delete Search Filter'
        content={'Are you sure you want to delete this Search Filter?'}
        open={isDeleteModalOpen}
        onCancel={() => {
          setIsDeleteModalOpen(false);
        }}
        cancelText={'No, keep the search filter'}
        onOk={async () => {
          selectedSearchFilter?.id && (await deleteSearchFilter(selectedSearchFilter.id));
          setIsDeleteModalOpen(false);
          setSelectedSearchFilter(null);
        }}
        okText={'Yes, delete it'}
      />
    </>
  );
};

export default SearchFiltersPage;
