import { FC } from 'react';
import { Button as AntButton, ButtonProps } from 'antd';
import styled from 'styled-components';

interface OnChangeHandler {
  (e: any): void;
}

interface MyButtonProps extends ButtonProps {
  onClick?: OnChangeHandler;
}

const ButtonWrapper = styled.div``;

const Button: FC<MyButtonProps> = ({
  onClick = () => {},
  ...rest
}: MyButtonProps) => {
  const handleOnClick = (e: any) => {
    onClick?.(e);
  };

  return (
    <ButtonWrapper>
      <AntButton onClick={handleOnClick} {...rest} />
    </ButtonWrapper>
  );
};

export default Button;
