import { FC } from 'react';
import { Modal as AntdModal } from 'antd';
import CreateCategoryForm from '../Forms/Category/CreateCategoryForm';

const CreateCategoryModal: FC<any> = ({ setIsOpen, isOpen, category }) => {
  const handleOk = () => {
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      <AntdModal
        title='Basic Modal'
        open={isOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <CreateCategoryForm
          onCancel={handleCancel}
          onOk={handleOk}
          category={category}
        />
      </AntdModal>
    </>
  );
};

export default CreateCategoryModal;
