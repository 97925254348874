import { Button, Space, Table, TableColumnsType } from 'antd';
import { FC } from 'react';
import { ReportStatus, ReportWithUser } from '../../dto/Report.dto';
import dayjs from 'dayjs';
import { useDeleteReport, useReadReport } from '../../services/api/reports';

const Expandable: FC<any> = ({ reports }: { reports: ReportWithUser[] }) => {
  const { mutateAsync: readReport } = useReadReport();
  const { mutateAsync: deleteReport } = useDeleteReport();
  const columns: TableColumnsType<ReportWithUser> = [
    { title: 'Reason', dataIndex: 'reason', key: 'reason' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Device', dataIndex: 'deviceId', key: 'deviceId' },
    { title: 'Date', dataIndex: 'createdAt', key: 'createdAt' },
    {
      align: 'end',
      title: 'Action',
      key: 'operation',
      render: (_: any, record: ReportWithUser) => (
        <Space size='middle'>
          <Button
            onClick={() => {
              deleteReport(record.id);
            }}
          >
            Delete
          </Button>
          <Button
            onClick={() => {
              readReport(record.id);
            }}
          >
            Read
          </Button>
        </Space>
      ),
    },
  ];

  const data = reports.map((report) => ({
    ...report,
    createdAt: dayjs(report.createdAt).format('DD/MM/YYYY'),
  }));

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      rowClassName={(record: ReportWithUser) => {
        return record.status === ReportStatus.Read
          ? 'report_read'
          : 'report_unread';
      }}
      className='expandable-report-table-row-select'
    />
  );
};

export default Expandable;
