import { FC } from 'react';
import { Modal as AntdModal } from 'antd';
import CreateWatchFaceForm from '../Forms/WatchFace/CreateWatchFaceForm';

const CreateWatchFaceModal: FC<any> = ({ setIsOpen, isOpen, watchface }) => {
  const handleOk = () => {
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <AntdModal
          title='Basic Modal'
          open={isOpen}
          footer={null}
          onCancel={handleCancel}
        >
          <CreateWatchFaceForm
            onCancel={handleCancel}
            onOk={handleOk}
            watchface={watchface}
            isOpen={isOpen}
          />
        </AntdModal>
      )}
    </>
  );
};

export default CreateWatchFaceModal;
