import React from 'react';
import DefaultLayout from '../components/Layouts/DefaultLayout';

const HomePage: React.FC = () => {

  return (
    <>
      <DefaultLayout>
        <main>
          <p>Welcome Home</p>
        </main>
      </DefaultLayout>
    </>
  );
};

export default HomePage;
