import { Form, SelectProps } from 'antd';
import { FC, useEffect, useState } from 'react';
import {
  BackgroundColorType,
  CustomColorType,
  WFComplicationType,
  WFFaceStyle,
  WFFaceType,
  WFStyleType,
} from '../../../dto/WatchFace.dto';
import {
  getBackgroundColors,
  getCustomColors,
} from '../../../utils/complication';
import { getKeyByValue } from '../../../utils/helpers';
import Select from '../Controls/Select';
import ComplicationSlotsFields from './ComplicationSlots';
import { FieldType } from './CreateWatchFaceForm';

const complicationTypeOption: SelectProps['options'] = Object.keys(
  WFComplicationType
).map((key) => ({
  label: key,
  value: (WFComplicationType as any)[key],
}));

const styleTypeOption: SelectProps['options'] = Object.keys(WFStyleType).map(
  (key) => ({
    label: key,
    value: (WFStyleType as any)[key],
  })
);

const faceStyleOption: SelectProps['options'] = Object.keys(WFFaceStyle).map(
  (key) => ({
    label: key,
    value: (WFFaceStyle as any)[key],
  })
);

const typefaceOption: SelectProps['options'] = Object.keys(WFFaceType).map(
  (key) => ({
    label: key,
    value: (WFFaceType as any)[key],
  })
);

const ComplicationFormFields: FC<any> = ({
  form,
  slots,
  setSlots,
  ...args
}: any) => {
  const complicationType: WFComplicationType = Form.useWatch(
    'complicationType',
    form
  );
  const [backgroundColorOption, setBackgroundColorOption] = useState<
    SelectProps['options']
  >([]);
  const [customColorOption, setCustomColorOption] = useState<
    SelectProps['options']
  >([]);

  useEffect(() => {
    if (!complicationType) return;

    const backgroundColor = getBackgroundColors(complicationType);
    const customColor = getCustomColors(complicationType);
    if (backgroundColor.length) {
      setBackgroundColorOption(
        backgroundColor.map((value) => {
          const label = getKeyByValue(BackgroundColorType, value);

          if (
            ['BlackMeridian', 'BlackInfograph', 'BlackModularCompact'].includes(
              label
            )
          ) {
            return { label: 'Black', value: null };
          }
          if (['BlackCalifornia'].includes(value)) {
            return { label: 'Black', value: value };
          }
          if (
            ['WhiteCalifornia', 'WhiteInfograph', 'WhiteMeridian'].includes(
              value
            )
          ) {
            return { label: 'White', value: value };
          }
          return { label, value };
        })
      );
    } else {
      setBackgroundColorOption([]);
    }
    if (customColor.length) {
      setCustomColorOption(
        customColor.map((value) => ({
          label: getKeyByValue(CustomColorType, value),
          value: value,
        }))
      );
    } else {
      setCustomColorOption([]);
    }
  }, [complicationType]);

  return (
    <>
      <Form.Item<FieldType>
        name={'complicationType'}
        label='Complication Type'
        rules={[
          {
            required: true,
            message: 'Please select complication type!',
          },
        ]}
      >
        <Select
          style={{ width: '100%' }}
          placeholder='Complication Type'
          options={complicationTypeOption}
        />
      </Form.Item>

      {Boolean(backgroundColorOption?.length) && (
        <Form.Item<FieldType>
          name={'backgroundColor'}
          label='Complication Background Color'
        >
          <Select
            style={{ width: '100%' }}
            placeholder='Complication Background Color'
            options={backgroundColorOption}
          />
        </Form.Item>
      )}

      {Boolean(customColorOption?.length) && (
        <Form.Item<FieldType>
          name={'customColor'}
          label='Complication Custom Color'
        >
          <Select
            style={{ width: '100%' }}
            placeholder='Complication Custom Color'
            options={customColorOption}
          />
        </Form.Item>
      )}

      {[
        WFComplicationType.ModularCompact,
        WFComplicationType.ModularCompactColored,
      ].includes(complicationType) && (
        <Form.Item<FieldType>
          name={'styleType'}
          label='Complication Style Type'
          rules={[
            {
              required: true,
              message: 'Please select watchface style type!',
            },
          ]}
        >
          <Select
            style={{ width: '100%' }}
            placeholder='Complication Style Type'
            options={styleTypeOption}
          />
        </Form.Item>
      )}

      {complicationType === WFComplicationType.California && (
        <Form.Item<FieldType>
          name={'faceStyle'}
          label='Complication Face Style'
          rules={[
            {
              required: true,
              message: 'Please select complication face style',
            },
          ]}
        >
          <Select
            style={{ width: '100%' }}
            placeholder='Complication Face Style'
            options={faceStyleOption}
          />
        </Form.Item>
      )}

      {complicationType === WFComplicationType.California && (
        <Form.Item<FieldType>
          name={'typeface'}
          label='Complication Typeface'
          rules={[
            {
              required: true,
              message: 'Please select complication typeface!',
            },
          ]}
        >
          <Select
            style={{ width: '100%' }}
            placeholder='WatchFace Complication Typeface'
            options={typefaceOption}
          />
        </Form.Item>
      )}

      <ComplicationSlotsFields
        form={form}
        complicationType={complicationType}
        slots={slots}
        setSlots={setSlots}
      />
    </>
  );
};

export default ComplicationFormFields;
