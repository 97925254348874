import { QueryOptions, useMutation, useQuery } from "react-query";
import api from ".";
import { ConfigResponse, UpdateConfig } from "../../dto/request/config-request.dto";
import { ApiServiceErr, MutOpt } from "../../types/api.types";

export const useGetConfig = (
    opt?: QueryOptions<ConfigResponse>
) => {

    return useQuery<ConfigResponse, ApiServiceErr>(
        ['config'],
        async () => {
            const response = await api.get<ConfigResponse>(`/admin/config`, {});
            return response;
        },
        opt,
    );
};


export const useUpdateConfig = (opt?: MutOpt<any>) =>
    useMutation<any, ApiServiceErr, UpdateConfig>(async (data: UpdateConfig) => {
        console.log("🚀 ~ useMutation<any,ApiServiceErr,UpdateConfig> ~ data:", data)
        const response = await api.post(`/admin/config`, data);
        return response.data;
    }, opt);