import { ArrowRightOutlined, LogoutOutlined } from '@ant-design/icons';
import { FC, useContext } from 'react';
import { StoreContext } from '../../store/store';
import Avatar from '../Avatar';

const Header: FC<any> = ({ onOpen, isOpen }) => {
  const { authStore } = useContext(StoreContext);

  const handleLogout = async (): Promise<void> => authStore.logout();
  const user = authStore.user;

  return (
    <div className='flex justify-between'>
      <div>
        {isOpen && (
          <div className='cursor-pointer' onClick={onOpen}>
            <ArrowRightOutlined />
          </div>
        )}
      </div>
      <div>
        <div className='inline-flex justify-center items-center'>
          <Avatar src={user?.avatarUrl} userName={user?.username}/>
          <div className='flex items-center'>
            <span className='ml-2 text-sm font-medium cursor-default'>
              {user?.username}
            </span>
            <div className='ml-2 cursor-pointer' onClick={handleLogout}>
              <LogoutOutlined />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
