import { Table, Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import Button from '../components/Forms/Controls/Button';
import DefaultLayout from '../components/Layouts/DefaultLayout';
import { WatchFaceType, WFStatus } from '../dto/WatchFace.dto';
import {
  useGenerateTopWatchFaces,
  useGetTopWatchFaces,
} from '../services/api/watchface';
import { CategoryType } from '../dto/Category.dto';

interface DataType {
  key: string;
  image: { src: string; alt: string; type: 'video' | 'img' };
  name: string;
  type: string;
  categoryType: string;
  isFree: boolean;
  isApproved: boolean;
  category: string;
  status: WFStatus;
  tags: string[];

  installsCount: number;
  likesCount: number;
  reportsCount: number;

  styles: any;
}

const TopWatchFaces: React.FC = () => {
  const { Column } = Table;

  const [categoryType, setCategoryType] = useState<any>();

  const [dataSource, setDataSource] = useState<DataType[]>([]);

  const handleRegenerateWF = () => {
    generateTop();
  };

  const { data: watchFaces, isLoading } = useGetTopWatchFaces({ categoryType });
  const { mutate: generateTop, isLoading: isLoadingGenerate } =
    useGenerateTopWatchFaces();

  useEffect(() => {
    if (!isLoading && watchFaces?.data) {
      setDataSource(
        watchFaces.data?.map((el, index) => {
          const image =
            el.watchface.wfType === WatchFaceType.Complication
              ? el.watchface.complicationsInfo.previewUrl
              : el.watchface.styles.previewUrl;
          const isImage = ['png', 'jpg', 'heic'].includes(
            image.split('.').pop()
          );
          return {
            key: el.watchface.id,
            name: el.watchface.name,
            type: el.watchface.wfType,
            status: el.watchface.status,
            category: el.watchface.category.name,
            image: {
              src: image,
              alt: el.watchface.name,
              type: isImage ? 'img' : 'video',
            },
            tags: el.watchface.tags,
            isFree: el.watchface.isFree,
            isApproved: el.watchface.isApproved,
            installsCount: el.watchface.installsCount,
            likesCount: el.watchface.likesCount,
            reportsCount: el.watchface.reportsCount,
            styles: el.watchface.styles,
            categoryType: el.watchface.category.type,
          };
        })
      );
    }
  }, [isLoading, watchFaces]);

  return (
    <>
      <DefaultLayout>
        <main>
          <div>
            <h2 className='text-black'>Top WatchFace Page</h2>
            <Button onClick={handleRegenerateWF} disabled={isLoadingGenerate}>
              ReGenerate Top
            </Button>
          </div>

          <div>
            <Table
              dataSource={dataSource}
              loading={isLoading}
              pagination={{
                position: ['bottomCenter', 'topCenter'],
              }}
              onChange={(_page, filter) => {
                setCategoryType(
                  filter.categoryType?.[0] ? filter.categoryType[0] : undefined
                );
              }}
              expandable={{
                expandedRowRender: (record) => (
                  <div className='flex flex-col'>
                    <span>ID: {record.key}</span>
                    <span>Status: {record.status}</span>
                    <span>Installs: {record.installsCount}</span>
                    <span>Likes: {record.likesCount}</span>
                    <span>Reports: {record.reportsCount}</span>
                    <span>
                      Styles:{' '}
                      <pre>
                        <code>{JSON.stringify(record.styles, null, 2)}</code>
                      </pre>
                    </span>
                  </div>
                ),
              }}
            >
              <Column
                title='Image'
                dataIndex='image'
                key='image'
                render={(image) => (
                  <>
                    <div className='w-20 h-20 rbg-slate-400 flex items-center justify-center text-sm'>
                      {image.type === 'img' ? (
                        <img
                          src={image.src}
                          alt={image.alt}
                          className={'w-20 h-20 object-cover '}
                        />
                      ) : (
                        <video loop autoPlay muted>
                          <source src={image.src} />
                        </video>
                      )}
                    </div>
                  </>
                )}
              />
              <Column title='Name' dataIndex='name' key='name' />
              <Column title='Type' dataIndex='type' key='type' />
              <Column title='Category' dataIndex='category' key='category' />
              <Column
                title='Category Type'
                dataIndex='categoryType'
                key='categoryType'
                filters={[
                  {
                    text: 'All',
                    value: CategoryType.All,
                  },
                  {
                    text: 'Admin',
                    value: CategoryType.Admin,
                  },
                  {
                    text: 'Customer',
                    value: CategoryType.Customer,
                  },
                ]}
                filterMultiple={false}
              />
              <Column
                title='Tags'
                dataIndex='tags'
                key='tags'
                render={(tags: string[]) => (
                  <>
                    {tags.map((tag) => (
                      <Tag color='blue' key={tag}>
                        {tag}
                      </Tag>
                    ))}
                  </>
                )}
              />
              <Column
                title='Free'
                dataIndex='isFree'
                key='isFree'
                align={'center'}
                render={(isFree: boolean) => {
                  return (
                    <>
                      {isFree ? (
                        <CheckCircleOutlined />
                      ) : (
                        <CloseCircleOutlined />
                      )}
                    </>
                  );
                }}
              />
            </Table>
          </div>
        </main>
      </DefaultLayout>
    </>
  );
};

export default TopWatchFaces;
