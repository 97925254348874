import { Space, Table, Tag, Button as AntButton } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import Button from '../components/Forms/Controls/Button';
import DefaultLayout from '../components/Layouts/DefaultLayout';
import { WatchFace, WatchFaceType, WFStatus } from '../dto/WatchFace.dto';
import {
  useDeleteWatchFace,
  useGetWatchFaces,
} from '../services/api/watchface';
import CreateWatchFaceModal from '../components/Modals/CreateWatchFaceModal';
import Modal from '../components/Modal';
import { useGetCategories } from '../services/api/category';
import { CategoryType } from '../dto/Category.dto';

interface DataType {
  key: string;
  image: { src: string; alt: string; type: 'video' | 'img' };
  name: string;
  type: string;
  isFree: boolean;
  isApproved: boolean;
  category: string;
  categoryType: string;
  status: WFStatus;
  tags: string[];

  installsCount: number;
  likesCount: number;
  reportsCount: number;
  position: number;

  styles: any;
}

const WatchFacesPage: React.FC = () => {
  const { Column } = Table;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedWatchFace, setSelectedWatchFace] =
    useState<WatchFace | null>();

  const handleAddWatchFace = () => {
    setSelectedWatchFace(null);
    setIsModalOpen(true);
  };

  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [category, setCategory] = useState<any>();
  const [categoryType, setCategoryType] = useState<any>();
  const [status, setStatus] = useState<any>(WFStatus.Published);

  const [dataSource, setDataSource] = useState<DataType[]>([]);

  const { data: watchFaces, isLoading } = useGetWatchFaces({
    limit: limit,
    page: page,
    category: category,
    categoryType: categoryType,
    status: status,
  });

  const { data: categories } = useGetCategories({
    limit: 100,
    page: 1,
    type: categoryType
  });

  const { mutateAsync: deleteWatchFace } = useDeleteWatchFace();

  useEffect(() => {
    if (!isLoading && watchFaces?.data) {
      setTotal(watchFaces.resultsCount);
      setDataSource(
        watchFaces.data.map((el, index) => {
          const image =
            el.wfType === WatchFaceType.Complication
              ? el.complicationsInfo.previewUrl
              : el.styles.previewUrl;
          const isImage = ['png', 'jpg', 'heic'].includes(
            image.split('.').pop()
          );
          return {
            key: el.id,
            name: el.name,
            type: el.wfType,
            status: el.status,
            category: el.category.name,
            image: {
              src: image,
              alt: el.name,
              type: isImage ? 'img' : 'video',
            },
            tags: el.tags,
            isFree: el.isFree,
            isApproved: el.isApproved,
            installsCount: el.installsCount,
            likesCount: el.likesCount,
            reportsCount: el.reportsCount,
            styles: el.styles || el.complicationsInfo,
            position: el.position,
            categoryType: el.category.type,
          };
        })
      );
    }
  }, [watchFaces]);

  return (
    <>
      <DefaultLayout>
        <main>
          <div>
            <h2 className='text-black'>WatchFaces Page</h2>
            <Button onClick={handleAddWatchFace}>Add</Button>
          </div>

          <div>
            <Table
              dataSource={dataSource}
              loading={isLoading}
              pagination={{
                current: page,
                total: total,
                pageSize: limit,
                position: ['bottomCenter', 'topCenter'],
              }}
              onChange={(page, filter) => {
                setCategory(
                  filter.category?.[0] ? filter.category[0] : undefined
                );
                setCategoryType(
                  filter.categoryType?.[0] ? filter.categoryType[0] : undefined
                );
                setStatus(
                  filter.status?.[0] ? filter.status[0] : undefined
                )
                page.current && setPage(page.current);
                page.pageSize && setLimit(page.pageSize);
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    const wf = watchFaces?.data.find(
                      (w) => w.id === record.key
                    );
                    setSelectedWatchFace(wf);
                    setIsModalOpen(true);
                  },
                };
              }}
              expandable={{
                expandedRowRender: (record) => (
                  <div className='flex flex-col'>
                    <span>ID: {record.key}</span>
                    <span>Status: {record.status}</span>
                    <span>Installs: {record.installsCount}</span>
                    <span>Likes: {record.likesCount}</span>
                    <span>Reports: {record.reportsCount}</span>
                    <span>
                      Styles:{' '}
                      <pre>
                        <code>{JSON.stringify(record.styles, null, 2)}</code>
                      </pre>
                    </span>
                  </div>
                ),
              }}
            >
              <Column
                title='Image'
                dataIndex='image'
                key='image'
                render={(image) => (
                  <>
                    <div className='w-20 h-20 rbg-slate-400 flex items-center justify-center text-sm'>
                      {image.type === 'img' ? (
                        <img
                          src={image.src}
                          alt={image.alt}
                          className={'w-20 h-20 object-cover '}
                        />
                      ) : (
                        <video loop autoPlay muted>
                          <source src={image.src} />
                        </video>
                      )}
                    </div>
                  </>
                )}
              />
              <Column
                title='Position'
                dataIndex='position'
                key='position'
                width={'10%'}
              />
              <Column title='Name' dataIndex='name' key='name' />
              <Column title='Type' dataIndex='type' key='type' />
              <Column
                title='Category'
                dataIndex='category'
                key='category'
                filters={categories?.data?.map((category) => ({
                  text: category.name,
                  value: category.id,
                }))}
                filterMultiple={false}
              />
              <Column
                title='Category Type'
                dataIndex='categoryType'
                key='categoryType'
                filters={[
                  {
                    text: 'All',
                    value: CategoryType.All,
                  },
                  {
                    text: 'Admin',
                    value: CategoryType.Admin,
                  },
                  {
                    text: 'Customer',
                    value: CategoryType.Customer,
                  },
                ]}
                filterMultiple={false}
              />
              <Column
                title='Tags'
                dataIndex='tags'
                key='tags'
                render={(tags: string[]) => (
                  <>
                    {tags.map((tag) => (
                      <Tag color='blue' key={tag}>
                        {tag}
                      </Tag>
                    ))}
                  </>
                )}
              />
              <Column
                title='Free'
                dataIndex='isFree'
                key='isFree'
                align={'center'}
                render={(isFree: boolean) => {
                  return (
                    <>
                      {isFree ? (
                        <CheckCircleOutlined />
                      ) : (
                        <CloseCircleOutlined />
                      )}
                    </>
                  );
                }}
              />
              <Column
                title='Published'
                dataIndex='status'
                key='status'
                align={'center'}
                defaultFilteredValue={[WFStatus.Published]}
                filters={[
                  {
                    text: 'Published',
                    value: WFStatus.Published,
                  },
                  {
                    text: 'Unpublished',
                    value: WFStatus.Unpublished,
                  },
                  {
                    text: 'Banned',
                    value: WFStatus.Banned,
                  },
                  {
                    text: 'Suspected',
                    value: WFStatus.Suspected,
                  },
                ]}
                filterMultiple={false}
                render={(status: WFStatus) => {
                  return (
                    <>
                      {status === WFStatus.Published ? (
                        <CheckCircleOutlined />
                      ) : (
                        <CloseCircleOutlined />
                      )}
                    </>
                  );
                }}
              />
              <Column
                title='Action'
                key='action'
                render={(_: any, record: DataType) => (
                  <Space size='middle'>
                    <AntButton
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        const wf = watchFaces?.data.find(
                          (w) => w.id === record.key
                        );
                        setSelectedWatchFace(wf);
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      Delete{' '}
                    </AntButton>
                  </Space>
                )}
              />
            </Table>
          </div>
        </main>
      </DefaultLayout>
      <CreateWatchFaceModal
        setIsOpen={setIsModalOpen}
        isOpen={isModalOpen}
        watchface={selectedWatchFace}
      />
      <Modal
        title='Delete WatchFace'
        content={'Are you sure you want to delete this WatchFace?'}
        open={isDeleteModalOpen}
        onCancel={() => {
          setIsDeleteModalOpen(false);
        }}
        cancelText={'No, keep the WatchFace'}
        onOk={async () => {
          selectedWatchFace?.id &&
            (await deleteWatchFace(selectedWatchFace.id));
          setIsDeleteModalOpen(false);
          setSelectedWatchFace(null);
        }}
        okText={'Yes, delete it'}
      />
    </>
  );
};

export default WatchFacesPage;
