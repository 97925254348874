import { QueryOptions, useMutation, useQuery, useQueryClient } from "react-query";
import api from ".";
import { CategoryType } from "../../dto/Category.dto";
import { CategoriesResponse, CategoryPaginationOptions, CreateCategory } from "../../dto/request/categories-request.dto";
import { ApiServiceErr, MutOpt } from "../../types/api.types";

export const useGetCategories = (
    { limit = 10, page = 1, type }: CategoryPaginationOptions,
    opt?: QueryOptions<CategoriesResponse>
) => {

    return useQuery<CategoriesResponse, ApiServiceErr>(
        ['categories', limit, page, type],
        async () => {
            let categoryType =type;
            if (type && type === CategoryType.All) {
                categoryType = undefined;
            }
            
            const response = await api.get<CategoriesResponse>(
                `/admin/category`,
                {
                    params: {
                        limit,
                        page,
                        type: categoryType,
                    }
                }
            );
            return response;
        },
        opt,
    );
};

export const useCreateCategory = (opt?: MutOpt<any>) =>
    useMutation<any, ApiServiceErr, CreateCategory>(async (data: CreateCategory) => {

        const response = await api.post(`/admin/category`, data);
        return response.data;
    }, opt);

export const useUpdateCategory = (opt?: MutOpt<any>) => {
    const queryClient = useQueryClient();

    return useMutation<any, ApiServiceErr, { id: string, data: CreateCategory }>(async ({ id, data }) => {

        const response = await api.put(`/admin/category/${id}`, data);
        return response.data;
    }, {
        ...opt,
        onSuccess: (data: any, variables: {
            id: string;
            data: CreateCategory;
        }, context: unknown) => {
            queryClient.invalidateQueries("categories");
            opt?.onSuccess?.(data, variables, context);
        }
    });
}

export const useDeleteCategory = (opt?: MutOpt<any>) => {
    const queryClient = useQueryClient();

    return useMutation<any, ApiServiceErr, string>(async (id) => {

        const response = await api.delete(`/admin/category/${id}`);
        return response;
    }, {
        ...opt,
        onSuccess: (data: any, id: string, context: unknown) => {
            queryClient.invalidateQueries("categories");
            opt?.onSuccess?.(data, id, context);
        }
    });
}