import { FC } from 'react';
import { Input as AntInput } from 'antd';
import styled from 'styled-components';
import { TextAreaProps } from 'antd/es/input';

interface OnChangeHandler {
  (e: any): void;
}

interface MyInputProps extends TextAreaProps{
  value?: number;
  onChange?: OnChangeHandler;
}

const InputWrapper = styled.div``;

const TextAreaInput: FC<MyInputProps> = ({
  value,
  onChange = () => {},
  ...rest
}: MyInputProps) => {
  const handleOnChange = (value: any) => {
    onChange?.(value);
  };
  
  return (
    <InputWrapper>
      <AntInput.TextArea value={value} onChange={handleOnChange} {...rest}/>
    </InputWrapper>
  );
};

export default TextAreaInput;
