import { Space, Table, Button as AntButton, Input, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../components/Layouts/DefaultLayout';
import Modal from '../../components/Modal';
import { User, UserStatus, UserType } from '../../dto/User.dto';
import {
  useBlockUser,
  useDeleteUser,
  useGetUsers,
  useUnblockUser,
} from '../../services/api/users';
import ExpandComponent from './ExpandComponent';

interface DataType extends User {
  key: string;
  image: { src: string; alt: string };
}

const UsersPage: React.FC = () => {
  const { Column } = Table;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>();

  const [type, setType] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [username, setUsername] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();

  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);

  const [dataSource, setDataSource] = useState<DataType[]>([]);

  const { data: users, isLoading } = useGetUsers({
    limit: limit,
    page: page,
    type: type,
    status: status,
    username: username,
    email: email,
  });
  const { mutateAsync: deleteUser } = useDeleteUser();
  const { mutateAsync: blockUser } = useBlockUser();
  const { mutateAsync: UnblockUser } = useUnblockUser();

  useEffect(() => {
    if (!isLoading && users?.data) {
      setTotal(users.resultsCount);
      setDataSource(
        users.data?.map((el) => {
          return {
            key: el.id,
            image: { src: el.avatarUrl, alt: el.username },
            ...el,
          };
        })
      );
    }
  }, [isLoading, users]);

  const handleOnDelete = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    id: string
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedUser(users?.data.find((w) => w.id === id));
    setIsDeleteModalOpen(true);
  };

  const handleOnBan = async (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    id: string
  ) => {
    try {
      await blockUser(id);
    } catch (error) {
      console.log('🚀 ~ handleOnBan ~ error:', error);
    }
  };

  const handleOnUnBan = async (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    id: string
  ) => {
    try {
      await UnblockUser(id);
    } catch (error) {
      console.log('🚀 ~ handleOnBan ~ error:', error);
    }
  };

  return (
    <>
      <DefaultLayout>
        <main>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Input
                placeholder='Username'
                value={username}
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
              />
            </Col>
            <Col span={12}>
              <Input
                placeholder='email'
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </Col>
          </Row>
          <div>
            <Table
              dataSource={dataSource}
              loading={isLoading}
              pagination={{
                current: page,
                total: total,
                pageSize: limit,
                position: ['bottomCenter', 'topCenter'],
              }}
              onChange={(page, filter) => {
                setStatus(filter.status?.[0] ? filter.status[0] : undefined);
                setType(filter.type?.[0] ? filter.type[0] : undefined);

                page.current && setPage(page.current);
                page.pageSize && setLimit(page.pageSize);
              }}
              //   onRow={(record, rowIndex) => {}}
              expandable={{
                expandedRowRender: (record) => (
                  <ExpandComponent user={record} />
                ),
              }}
            >
              <Column
                title='Image'
                dataIndex='image'
                key='image'
                render={(image) => (
                  <>
                    <div className='w-20 h-20 rbg-slate-400 flex items-center justify-center text-sm'>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className={'w-20 h-20 object-cover '}
                      />
                    </div>
                  </>
                )}
              />

              <Column title='Email' dataIndex='email' key='email' />
              <Column title='Username' dataIndex='username' key='username' />
              <Column
                title='Type'
                dataIndex='type'
                key='type'
                filterMultiple={false}
                filters={[
                  {
                    text: 'Admin',
                    value: UserType.Admin,
                  },
                  {
                    text: 'Customer',
                    value: UserType.Customer,
                  },
                ]}
              />
              <Column
                title='Status'
                dataIndex='status'
                key='status'
                filterMultiple={false}
                filters={[
                  {
                    text: 'Active',
                    value: UserStatus.Active,
                  },
                  {
                    text: 'Blocked',
                    value: UserStatus.Blocked,
                  },
                ]}
              />
              <Column
                title='Action'
                key='action'
                render={(_: any, record: DataType) => (
                  <Space size='middle'>
                    <AntButton
                      onClick={(event) => handleOnDelete(event, record.key)}
                    >
                      Delete
                    </AntButton>
                    {record.status === UserStatus.Active && (
                      <AntButton
                        onClick={(event) => handleOnBan(event, record.key)}
                      >
                        Block
                      </AntButton>
                    )}
                    {record.status === UserStatus.Blocked && (
                      <AntButton
                        onClick={(event) => handleOnUnBan(event, record.key)}
                      >
                        UnBlock
                      </AntButton>
                    )}
                  </Space>
                )}
              />
            </Table>
          </div>
        </main>
      </DefaultLayout>
      <Modal
        title='Delete WatchFace'
        content={'Are you sure you want to delete this WatchFace?'}
        open={isDeleteModalOpen}
        onCancel={() => {
          setIsDeleteModalOpen(false);
        }}
        cancelText={'No, keep the WatchFace'}
        onOk={async () => {
          if (selectedUser?.id) await deleteUser(selectedUser.id);
          setIsDeleteModalOpen(false);
          setSelectedUser(null);
        }}
        okText={'Yes, delete it'}
      />
    </>
  );
};

export default UsersPage;
