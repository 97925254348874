import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StoreContext } from '../store/store';

const UnAuthGuard: React.FC<{ component: React.ReactNode }> = observer(
  ({ component }) => {
    const navigate = useNavigate();

    const [status, setStatus] = useState(false);

    const { authStore } = useContext(StoreContext);
    const isAuthenticated = authStore.isAuthenticated;

    useEffect(() => {
      authStore.getUser();
    }, []);

    useEffect(() => {
      if (!component) return;
      if (isAuthenticated) {
        navigate(`/`);
      }
      setStatus(true);
    }, [component, isAuthenticated]);

    return status ? (
      <React.Fragment>{component}</React.Fragment>
    ) : (
      <React.Fragment></React.Fragment>
    );
  }
);

export default UnAuthGuard;
