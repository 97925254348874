import { AxiosRequestConfig } from 'axios';
import http from './http';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    async get<T>(url: string, config?: AxiosRequestConfig) {
        try {
            const response = await http.get(url, config);
            const data: T = response.data;
            return data;
        } catch (err: any) {
            throw err
        }
    },
    async post(url: string, send: object, config?: AxiosRequestConfig) {
        try {
            const response = await http.post(url, send, config);
            return response;
        } catch (err: any) {
            throw err
        }
    },
    async put(url: string, send: object, config?: AxiosRequestConfig) {
        try {
            const response = await http.put(url, send);
            return response;
        } catch (err: any) {
            throw err
        }
    },
    async delete(url: string) {
        try {
            await http.delete(url);
            return true;
        } catch (err: any) {
            throw err
        }
    },
};