import { Col, Form, message, Row, SelectProps } from 'antd';
import { FC, useEffect } from 'react';
import { UpdateConfig } from '../../../dto/request/config-request.dto';
import { WFStatus } from '../../../dto/WatchFace.dto';
import { useUpdateConfig } from '../../../services/api/config';
import Button from '../Controls/Button';
import Select from '../Controls/Select';
import TextInput from '../Controls/TextInput';

interface OnChangeHandler {
  (e: any): void;
}

interface CreateConfigFormProps {
  onFinish?: OnChangeHandler;
  onChange?: OnChangeHandler;
  onOk?: OnChangeHandler;
  config?: UpdateConfig;
}

export type FieldType = Partial<UpdateConfig>;

const wfSattusOption: SelectProps['options'] = [
    { label: 'Published', value: WFStatus.Published },
    { label: 'Unpublished', value: WFStatus.Unpublished },
    { label: 'Banned', value: WFStatus.Banned },
    { label: 'Suspected', value: WFStatus.Suspected },
];

const CreateConfigForm: React.FC<CreateConfigFormProps> = ({
  config,
  onChange,
  onOk,
  onFinish,
}: CreateConfigFormProps) => {
  const [form] = Form.useForm();

  const { mutate: updateConfig } = useUpdateConfig({
    onSuccess: (data) => {
      form.resetFields();
      console.log(data);
      onOk?.(data);
    },
    onError: (err) => {
      const errorMessage =
        err?.response?.data?.error?.message || 'Something went wrong';
      const errorArray = err?.response?.data?.error?.errors;
      if (Array.isArray(errorArray)) {
        errorArray.forEach((element) => {
          const errors = element.constraints;
          message.error(Object.values(errors)[0]?.toString());
        });
      } else {
        message.error(errorMessage);
      }
    },
  });

  const handleOnFinish = async (values: any): Promise<void> => {
    const body = {
      ...values,
    };
    updateConfig(body);
  };

  const handleOnChange = (e: any): void => {
    onChange?.(e);
  };

  return (
    <Form
      onChange={handleOnChange}
      onFinish={handleOnFinish}
      form={form}
      initialValues={config}
      autoComplete='off'
      layout='vertical'
    >
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Form.Item<FieldType>
            name={'CUSTOMER_POPULARITY_DAYS'}
            label='Customer popularity days'
          >
            <TextInput placeholder='Customer popularity days' type='number' />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item<FieldType> name={'TRENDING_DAYS'} label='Trending days'>
            <TextInput placeholder='Trending days' type='number' />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item<FieldType> name={'TOP_DAYS'} label='Top days'>
            <TextInput placeholder='Top days' type='number' />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item<FieldType>
            name={'REPORTS_FOR_SUSPECT'}
            label='Reports for suspect wf'
          >
            <TextInput placeholder='Reports for suspect wf' type='number' />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item<FieldType>
            name={'DEFAULT_CUSTOMER_WF_STATUS'}
            label='Default WF Status'
          >
            <Select
              style={{ width: '100%' }}
              placeholder='WF status'
              options={wfSattusOption}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ display: 'flex', gap: '10px' }}>
          <Button htmlType='submit'>Update</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CreateConfigForm;
