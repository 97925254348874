import { observable, action, makeObservable } from 'mobx'
import { LoginRequest } from '../dto/request/login-request.dto'
import { AuthService } from '../services/auth'
import { User } from '../types/User'

class AuthStore {
    isAuthenticated: boolean = false;
    isLoading: boolean = true;
    isFailure: boolean = false;
    user: User | null = null;


    constructor(private readonly authService: AuthService) {
        makeObservable(this, {
            isAuthenticated: observable,
            isLoading: observable,
            isFailure: observable,
            user: observable,
            login: action,
        })
    }

    async login(data: LoginRequest) {
        try {
            this.isLoading = true;
            const user = await this.authService.login(data);

            this.user = user;
            this.isAuthenticated = true;
            this.isFailure = false;
            this.isLoading = false;
        } catch (error: any) {
            this.user = null;
            this.isAuthenticated = false;
            this.isFailure = true;
            this.isLoading = false;
            throw new Error(error);
        }
    }

    async getUser() {
        try {
            this.isLoading = true;
            const user = await this.authService.getUserProfile();
            this.user = user;
            this.isAuthenticated = true;
            this.isFailure = false;
            this.isLoading = false;
        } catch (error: any) {
            this.user = null;
            this.isAuthenticated = false;
            this.isFailure = true;
            this.isLoading = false;
        }
    }

    async logout() {
        await this.authService.logout()
        this.user = null;
        this.isAuthenticated = false;
        this.isFailure = true;
        this.isLoading = false;
    }
}

export default AuthStore;