export enum ReportStatus {
    Read = 'read',
    Unread = 'unread'
  }
  
export interface Report {
    readonly id: string;
    readonly reason: string;
    readonly email: string;
    readonly deviceId: string;
    readonly createdAt: string;
    readonly status:  ReportStatus;
}

export interface ReportWithUser extends Report {
    user: {
        readonly id: string;
        readonly username: string;
        readonly email: string;
        readonly avatarId: string;
        readonly avatarUrl: string;
    }
}