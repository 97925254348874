import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { LoginRequest } from '../dto/request/login-request.dto';
import { auth } from './firebase';
import api from './api/index'
import { User } from '../types/User';
import { localStorageKeys } from '../types/localStorageKeys';
export class AuthService {

    login(data: LoginRequest) {
        return new Promise<User>(async (resolve, reject) => {
            signInWithEmailAndPassword(auth, data.email, data.password)
                .then(async (userCredential) => {
                    const fireBaseUser = userCredential.user;
                    const token = await fireBaseUser.getIdToken();
                    localStorage.setItem(localStorageKeys.FBToken, token);
                    const user = await this.getUserProfile();
                    resolve(user)
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorCode, errorMessage);
                    reject(error)
                });
        })
    }

    getUserProfile() {
        return new Promise<User>(async (resolve, reject) => {
            try {
                const user = await api.get<User>('/admin/user')
                if (user.type !== 'admin') throw new Error('User is not admin')
                resolve(user)

            } catch (error) {
                reject(error)
            }
        })
    }

    logout() {
        return new Promise(async (resolve, reject) => {
            signOut(auth).then(() => {
                localStorage.removeItem(localStorageKeys.FBToken);
                resolve(true)
            }).catch((error) => {
                reject(false)
            });
        })
    }

    getProfile() {
        return new Promise(async (resolve, reject) => {
            await auth.onAuthStateChanged((user) => {
                if (user) {
                    resolve(user);
                } else {
                    resolve(false);
                }
            });
        })
    }
}
