import { Form } from 'antd';
import { FC, useState } from 'react';
import { LoginRequest } from '../../../dto/request/login-request.dto';
import Button from '../Controls/Button';
import PasswordInput from '../Controls/PasswordInput';
import TextInput from '../Controls/TextInput';

interface OnChangeHandler {
  (e: any): void;
}

interface SignInFormProps {
  onSubmit?: OnChangeHandler;
  onFinish?: OnChangeHandler;
  onChange?: OnChangeHandler;
}

export type FieldType = Partial<LoginRequest>;

const SignInForm: FC<SignInFormProps> = ({
  onSubmit,
  onFinish,
  onChange,
  ...args
}: SignInFormProps) => {
  const [form] = Form.useForm();
  const [error, setError] = useState<string>();

  const handleOnFinish = async (): Promise<void> => {
    try {
      setError('');
      const data: LoginRequest = form.getFieldsValue();
      await onSubmit?.(data);
    } catch (error) {
      setError('Email or password are incorrect');
    }
  };

  const handleOnChange = (e: any): void => {
    onChange?.(e);
  };

  return (
    <Form
      onChange={handleOnChange}
      onFinish={handleOnFinish}
      form={form}
      {...args}
    >
      <Form.Item<FieldType>
        name={'email'}
        rules={[
          { required: true, message: 'Please input your email address!' },
          {
            validator: (_, value) => {
              if (!value) return Promise.resolve();
              if (/^\S+@\S+\.\S+$/.test(value)) {
                return Promise.resolve();
              } else {
                return Promise.reject('Enter a valid email address!');
              }
            },
          },
        ]}
      >
        <TextInput type='email' />
      </Form.Item>

      <Form.Item<FieldType>
        name={'password'}
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <PasswordInput />
      </Form.Item>

      <div>
        <span>{error}</span>
      </div>

      <Form.Item<FieldType> name={'password'}>
        <Button htmlType='submit'>Login</Button>
      </Form.Item>
    </Form>
  );
};

export default SignInForm;
