import { Col, Form, message, Row, SelectProps, Switch } from 'antd';
import { FC, useEffect } from 'react';
import { CreateSearchFilter } from '../../../dto/request/search-filters-request.dto';
import {
  SearchFilter,
  CategoryType,
  SearchFilterType,
} from '../../../dto/SearchFilter.dto';
import { useGetCategories } from '../../../services/api/category';
import {
  useCreateSearchFilter,
  useUpdateSearchFilter,
} from '../../../services/api/searchFilters';
import Button from '../Controls/Button';
import Select from '../Controls/Select';
import TextInput from '../Controls/TextInput';

interface OnChangeHandler {
  (e: any): void;
}

interface CreateSearchFilterFormProps {
  onSubmit?: OnChangeHandler;
  onFinish?: OnChangeHandler;
  onChange?: OnChangeHandler;
  onCancel?: OnChangeHandler;
  onOk?: OnChangeHandler;
  searchFilter?: SearchFilter;
}

export type FieldType = Partial<CreateSearchFilter>;

const categoryTypeOption: SelectProps['options'] = [
  { label: 'All', value: CategoryType.All },
  { label: 'Admin', value: CategoryType.Admin },
  { label: 'Customer', value: CategoryType.Customer },
];

const searchFilterTypeOption: SelectProps['options'] = [
  { label: 'Category', value: SearchFilterType.Category },
  { label: 'SearchTag', value: SearchFilterType.SearchTag },
  { label: 'New', value: SearchFilterType.New },
  { label: 'Top (30 day)', value: SearchFilterType.Top },
  { label: 'Trending (3 day)', value: SearchFilterType.Trending },
];

const SearchFilterForm: FC<any> = ({
  onSubmit,
  onFinish,
  onChange,
  onCancel,
  onOk,
  searchFilter,
  ...args
}: CreateSearchFilterFormProps) => {
  const [form] = Form.useForm();
  const categoryType = Form.useWatch('categoryType', form) as CategoryType;
  const type = Form.useWatch('type', form) as SearchFilterType;

  const { data: categories } = useGetCategories({
    limit: 1000,
    page: 1,
    type: categoryType,
  });

  const { mutate: createSearchFilter } = useCreateSearchFilter({
    onSuccess: (data) => {
      form.resetFields();
      onOk?.(data);
    },
    onError: (err) => {
      const errorMessage =
        err?.response?.data?.error?.message || 'Something went wrong';
      const errorArray = err?.response?.data?.error?.errors;
      if (Array.isArray(errorArray)) {
        errorArray.forEach((element) => {
          const errors = element.constraints;
          message.error(Object.values(errors)[0]?.toString());
        });
      } else {
        message.error(errorMessage);
      }
    },
  });

  const { mutate: updateSearchFilter } = useUpdateSearchFilter({
    onSuccess: (data) => {
      form.resetFields();
      onOk?.(data);
    },
    onError: (err) => {
      const errorMessage =
        err?.response?.data?.error?.message || 'Something went wrong';
      const errorArray = err?.response?.data?.error?.errors;
      if (Array.isArray(errorArray)) {
        errorArray.forEach((element) => {
          const errors = element.constraints;
          message.error(Object.values(errors)[0]?.toString());
        });
      } else {
        message.error(errorMessage);
      }
    },
  });

  const handleOnFinish = async (values: any): Promise<void> => {
    const body = {
      ...values,
      position: +values.position,
    };
    if (searchFilter?.id) {
      updateSearchFilter({ id: searchFilter.id, data: body });
    } else {
      createSearchFilter(body);
    }
  };

  const handleOnChange = (e: any): void => {
    onChange?.(e);
  };

  const handleOnCancel = (e: any): void => {
    form.resetFields();
    onCancel?.(e);
  };

  useEffect(() => {
    if (searchFilter === null) {
      form.resetFields();

      return;
    }

    form.setFieldsValue({
      ...searchFilter,
    });
  }, [form, searchFilter]);

  return (
    <Form
      onChange={handleOnChange}
      onFinish={handleOnFinish}
      form={form}
      initialValues={searchFilter}
      autoComplete='off'
      layout='vertical'
      {...args}
    >
      <Row>
        <Col span={24}>
          <Form.Item<FieldType>
            name={'isActive'}
            label='Is Active'
            valuePropName='checked'
          >
            <Switch defaultChecked={false} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item<FieldType> name={'position'} label='Position'>
            <TextInput placeholder='Search filter position' type='number' />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item<FieldType>
            name={'name'}
            label='Name'
            rules={[
              { required: true, message: 'Please input search filter name!' },
            ]}
          >
            <TextInput placeholder='Search filter display name' />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item<FieldType>
            name={'type'}
            label='Type'
            rules={[
              { required: true, message: 'Please select search filter type!' },
            ]}
          >
            <Select
              style={{ width: '100%' }}
              placeholder='Search filter type'
              options={searchFilterTypeOption}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item<FieldType>
            name={'categoryType'}
            label='Category Type'
            rules={[
              {
                required: true,
                message: 'Please input search filter category type!',
              },
            ]}
          >
            <Select
              style={{ width: '100%' }}
              placeholder='Search filter category type'
              options={categoryTypeOption}
            />
          </Form.Item>
        </Col>

        {type === SearchFilterType.Category && (
          <Col span={24}>
            <Form.Item<FieldType>
              name={'categoryId'}
              label='Category'
              rules={[
                {
                  required: true,
                  message: 'Please select watchface category!',
                },
              ]}
            >
              <Select
                // showSearch
                style={{ width: '100%' }}
                placeholder='WatchFace Category'
                options={categories?.data?.map((el) => {
                  return { label: `${el.name} - ${el.type}`, value: el.id };
                })}
              />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row>
        <Col span={24} style={{ display: 'flex', gap: '10px' }}>
          <Button htmlType='submit'>
            {searchFilter?.id ? 'Update' : 'Create'}
          </Button>
          <Button onClick={handleOnCancel}>Cancel</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchFilterForm;
