import { FC } from 'react';
import { Input as AntInput } from 'antd';
import styled from 'styled-components';
import { PasswordProps } from 'antd/es/input';

interface OnChangeHandler {
  (e: any): void;
}

interface MyInputProps extends PasswordProps{
  value?: number;
  onChange?: OnChangeHandler;
}

const InputWrapper = styled.div``;

const PasswordInput: FC<MyInputProps> = ({
  value,
  onChange = () => {},
  ...rest
}: MyInputProps) => {
  const handleOnChange = (value: any) => {
    onChange?.(value);
  };

  return (
    <InputWrapper>
      <AntInput.Password value={value} onChange={handleOnChange} {...rest}/>
    </InputWrapper>
  );
};

export default PasswordInput;
