import { QueryOptions, useMutation, useQuery, useQueryClient } from "react-query";
import api from ".";
import { UsersResponse } from "../../dto/request/users-request.dto";
import { UserStatus, UserType } from "../../dto/User.dto";
import { ApiServiceErr, MutOpt } from "../../types/api.types";

export const useGetUsers = (
    { limit, page, type, status, email, username }: { limit: number, page: number, type?: UserType, status?: UserStatus, email?: string, username?: string },
    opt?: QueryOptions<UsersResponse>
) => {

    return useQuery<UsersResponse, ApiServiceErr>(
        ['users', limit, page, type, status, email, username],
        async () => {
            const response = await api.get<UsersResponse>(
                `/admin/user/all`,
                {
                    params: {
                        limit,
                        page,
                        type,
                        status,
                        username,
                        email
                    }
                }
            );
            return response;
        },
        opt,
    );
};

export const useDeleteUser = (opt?: MutOpt<any>) => {
    const queryClient = useQueryClient();

    return useMutation<any, ApiServiceErr, string>(async (id) => {
        const response = await api.delete(`/admin/user/${id}`);
        return response;
    }, {
        ...opt,
        onSuccess: (data: any, id: string, context: unknown) => {
            queryClient.invalidateQueries("users");
            opt?.onSuccess?.(data, id, context);
        }
    });
}

export const useBlockUser = (opt?: MutOpt<any>) => {
    const queryClient = useQueryClient();

    return useMutation<any, ApiServiceErr, string>(async (id) => {
        const response = await api.post(`/admin/user/block/${id}`, {});
        return response;
    }, {
        ...opt,
        onSuccess: (data: any, id: string, context: unknown) => {
            queryClient.invalidateQueries("users");
            opt?.onSuccess?.(data, id, context);
        }
    });
}

export const useUnblockUser = (opt?: MutOpt<any>) => {
    const queryClient = useQueryClient();

    return useMutation<any, ApiServiceErr, string>(async (id) => {
        const response = await api.post(`/admin/user/unblock/${id}`, {});
        return response;
    }, {
        ...opt,
        onSuccess: (data: any, id: string, context: unknown) => {
            queryClient.invalidateQueries("users");
            opt?.onSuccess?.(data, id, context);
        }
    });
}