import React, { useContext, FC } from 'react';
import { StoreContext } from '../store/store';
import { observer } from 'mobx-react';
import SignInForm from '../components/Forms/SignnIn/SignInForm';
import AnonymousLayout from '../components/Layouts/AnonymousLayout';
import { LoginRequest } from '../dto/request/login-request.dto';

const SignInPage: FC = observer(() => {
  const { authStore } = useContext(StoreContext);

  const onLogin = async ({ email, password }: LoginRequest): Promise<void> =>
    authStore.login({ email, password });

  return (
    <>
      <AnonymousLayout>
        <section className='h-screen'>
          <div className='h-full flex items-center justify-center'>
            <SignInForm onSubmit={onLogin} />
          </div>
        </section>
      </AnonymousLayout>
    </>
  );
});

export default SignInPage;
