import {
  Button,
  Col,
  Form,
  message,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  SelectProps,
  Upload,
  UploadProps,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/es/upload';
import {
  getSlots,
  getSystemSlot,
  getSystemSlotsValues,
} from '../../../utils/complication';
import { getKeyByValue } from '../../../utils/helpers';
import {
  ComplicationSlotType,
  WatchFaceType,
  WFComplicationType,
  WFFaceStyle,
  SystemComplicationPreviewModel,
  WFInformationType,
  ComplicationSystemSlotType,
} from '../../../dto/WatchFace.dto';

const ComplicationSlotsFields: FC<any> = ({
  form,
  complicationType,
  slots,
  setSlots,
  ...args
}: any) => {
  console.log('🚀 ~ slots:', slots);
  const faceStyle: WFFaceStyle = Form.useWatch('faceStyle', form);
  const availableSlots = getSlots(complicationType, { faceStyle });

  const onChangeFile = (
    type: ComplicationSlotType,
    data: any,
    additionalData: any
  ) => {
    const temp = slots;
    temp[type] = { ...temp[type], file: data, ...additionalData };
    setSlots(temp);
  };
  return (
    <>
      {availableSlots?.map((slot) => {
        return (
          <Slot
            key={slot}
            type={slot}
            faceStyle={faceStyle}
            complicationType={complicationType}
            defaultValue={slots[getSystemSlot(slot)]?.informationType}
            defaultFile={
              slots[slot]?.fileUrl
                ? {
                    uid: slots[slot]?.complicationId,
                    name: `${slot} - ${slots[slot]?.complicationId}`,
                    status: 'done',
                    url: slots[slot]?.previewUrl,
                  }
                : null
            }
            onChangeFile={onChangeFile}
          />
        );
      })}
    </>
  );
};

const Slot: FC<any> = ({
  type,
  onChangeFile,
  defaultFile,
  faceStyle,
  complicationType,
  defaultValue,
}: {
  type: ComplicationSlotType;
  onChangeFile: (
    type: ComplicationSlotType | ComplicationSystemSlotType,
    file: any,
    additionalData?: any
  ) => void;
  defaultFile: any;
  faceStyle: WFFaceStyle;
  complicationType: WFComplicationType;
  defaultValue: WFInformationType;
}) => {
  const isDisableNew =
    type === ComplicationSlotType.SubdialTop &&
    complicationType === WFComplicationType.California;
  const [value, setValue] = useState(isDisableNew ? 'select' : 'new');
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleOnChangeValue = ({ target: { value } }: RadioChangeEvent) => {
    setValue(value);
  };

  const slotsOptions: SelectProps['options'] = getSystemSlotsValues(
    complicationType,
    type
  )?.map((value) => ({
    label: getKeyByValue(WFInformationType, value),
    value: value,
  }));

  const options = [
    { label: 'New', value: 'new', disabled: isDisableNew },
    {
      label: 'Select',
      value: 'select',
      disabled: !Boolean(slotsOptions?.length),
    },
  ];

  const beforeUpload = (file: RcFile) => {
    const isSupportType = ['application/zip'].includes(file.type);
    if (!isSupportType) {
      message.error('Unsupported file type');
    }

    return isSupportType;
  };

  const handleChange: UploadProps['onChange'] = async (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === 'uploading') {
      info.file.status = 'done';
      setFileList(info.fileList);
      onChangeFile(type, info.file.originFileObj);
    }

    if (info.file.status === 'removed') {
      setFileList([]);
      onChangeFile(type, null);
    }
  };

  const handleOnChangeSelect = (data: WFInformationType) => {
    const additionalData: Omit<SystemComplicationPreviewModel, 'familyType'> = {
      informationType: data,
    };
    onChangeFile(getSystemSlot(type), null, additionalData);
  };

  useEffect(() => {
    if (defaultFile) {
      setFileList([defaultFile]);
      setValue('new');
    }
  }, [defaultFile]);

  return (
    <>
      <div>
        <Row>
          <Col span={24}>
            <h5>{getKeyByValue(ComplicationSlotType, type)}</h5>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Radio.Group
              options={options}
              onChange={handleOnChangeValue}
              value={value}
              optionType='button'
            />
          </Col>
        </Row>
        <Row>
          {value === 'new' && (
            <Col span={24}>
              <Upload
                name={type}
                fileList={fileList}
                onChange={handleChange}
                beforeUpload={beforeUpload}
                maxCount={1}
                listType='picture'
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Col>
          )}
          {value === 'select' && (
            <Col span={24}>
              <Select
                onChange={handleOnChangeSelect}
                style={{ width: '100%' }}
                placeholder='Complication Slot'
                options={slotsOptions}
                defaultValue={defaultValue}
              />
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default ComplicationSlotsFields;
