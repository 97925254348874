import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StoreContext } from '../store/store';

const AuthGuard: React.FC<{ component: React.ReactNode }> = observer(
  ({ component }) => {
    const navigate = useNavigate();

    const [status, setStatus] = useState(false);

    const { authStore } = useContext(StoreContext);
    const isAuthenticated = authStore.isAuthenticated;
    const isLoading = authStore.isLoading;

    useEffect(() => {
      authStore.getUser();
    }, []);

    useEffect(() => {
      if (!component) return;
      if (isLoading) return;

      if (isAuthenticated) {
        setStatus(true);
      } else {
        navigate(`/login`);
      }
    }, [component, isAuthenticated, isLoading]);

    return status ? (
      <React.Fragment>{component}</React.Fragment>
    ) : (
      <React.Fragment></React.Fragment>
    );
  }
);

export default AuthGuard;
