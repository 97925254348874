import { AxiosError, AxiosInstance, InternalAxiosRequestConfig, AxiosResponse } from "axios";
import { localStorageKeys } from "../../types/localStorageKeys";
import { auth } from "../firebase";

const onRequest = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    let firebaseToken = null;
    if (auth.currentUser) {
        firebaseToken =  await auth.currentUser.getIdToken();
        localStorage.setItem(localStorageKeys.FBToken, firebaseToken);
    } else {
        firebaseToken = localStorage.getItem(localStorageKeys.FBToken);
    }
    if (!firebaseToken) return config;
    config.headers['Firebase'] = `${firebaseToken}`;

    return config;
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    console.log("🚀 ~ onRequestError ~ error:", error.response?.data);
    console.log("🚀 ~ onRequestError ~ auth.currentUser:", auth.currentUser)
    if (auth.currentUser) {
        auth.currentUser.getIdToken()
            .then(function (idToken) {
                localStorage.setItem(localStorageKeys.FBToken, idToken);
                console.log("🚀 ~ idToken:", idToken)

            }).catch(function (error) {
                console.log("🚀 ~ error:", error)

            });
    }


    return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
    // console.info(`[response] [${JSON.stringify(response)}]`);

    return response;
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    console.error(`🚀 ~ [response error] [${JSON.stringify(error)}]`);

    return Promise.reject(error);
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);

    return axiosInstance;
}