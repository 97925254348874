import { Form } from 'antd';
import { UpdateConfig } from '../../dto/request/config-request.dto';
import { useGetConfig } from '../../services/api/config';
import DefaultLayout from '../../components/Layouts/DefaultLayout';
import CreateConfigForm from '../../components/Forms/Config/CreateConfigForm';

export type FieldType = Partial<UpdateConfig>;

const ConfigsPage: React.FC = () => {
  const [form] = Form.useForm();

  const { data: config, isLoading } = useGetConfig();

  const handleOnChange = (e: any): void => {
    // onChange?.(e);
  };

  const handleOnCancel = (e: any): void => {
    form.resetFields();
    // onCancel?.(e);
  };

  return (
    <>
      <DefaultLayout>
        <main>
          <div>
            {config && <CreateConfigForm config={config}/>}
          </div>
        </main>
      </DefaultLayout>
    </>
  );
};

export default ConfigsPage;
