import { FC } from 'react';
import { Modal as AntdModal } from 'antd';

const Modal: FC<any> = ({
  title,
  content,
  open,
  onOk,
  okText,
  onCancel,
  cancelText,
  currentAppointment,
  ...rest
}) => {
  return (
    <>
      <AntdModal
        title={title}
        open={open}
        onOk={onOk}
        okText={okText}
        okButtonProps={{
          className:
            'inline-block w-1/2 text-white rounded-[40px] h-[45px] bg-rose-500 hover:!bg-rose-600',
        }}
        onCancel={onCancel}
        cancelText={cancelText}
        cancelButtonProps={{
          className:
            'inline-block w-1/2 text-slate-700 hover:!text-slate-700 rounded-[40px] h-[45px] border-gray2 hover:!border-gray2 bg-transparent',
        }}
        width={720}
        wrapClassName={'antd-modal-custom'}
        maskStyle={{
          backgroundColor: '#0E0E33BF',
        }}
        centered
      >
        <p className='text-center text-2xl pt-10 pb-[70px] font-normal'>
          {content}
        </p>
      </AntdModal>
    </>
  );
};

export default Modal;
