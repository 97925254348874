import React from 'react';
import DefaultLayout from '../components/Layouts/DefaultLayout';
import ReportsTable from '../components/Reports/ReportsTable';

const ReportsPage: React.FC = () => {
  return (
    <>
      <DefaultLayout>
        <main>
          <div>
            <h2 className='text-black'>WatchFace With Reports</h2>
          </div>

          <div>
            <ReportsTable />
          </div>
        </main>
      </DefaultLayout>
    </>
  );
};

export default ReportsPage;
