import { QueryOptions, useMutation, useQuery, useQueryClient } from "react-query";
import api from ".";
import { CreateSearchFilter, SearchFiltersResponse } from "../../dto/request/search-filters-request.dto";
import { ApiServiceErr, MutOpt } from "../../types/api.types";

export const useGetSearchFilters = (
    { type, categoryType }: { type?: string, categoryType?: string },
    opt?: QueryOptions<SearchFiltersResponse>
) => {

    return useQuery<SearchFiltersResponse, ApiServiceErr>(
        ['searchFilters', type, categoryType],
        async () => {
            const response = await api.get<SearchFiltersResponse>(
                `/admin/search-filter`,
                {
                    params: {
                        type,
                        categoryType,
                    }
                }
            );
            return response;
        },
        opt,
    );
};

export const useCreateSearchFilter = (opt?: MutOpt<any>) => {
    const queryClient = useQueryClient();
    return useMutation<any, ApiServiceErr, CreateSearchFilter>(async (data: CreateSearchFilter) => {
        const body = {
            ...data,
            isActive: Boolean(data.isActive)
        };

        const response = await api.post(`/admin/search-filter`, body);
        return response.data;
    }, {
        ...opt,
        onSuccess: (data: CreateSearchFilter, variables: unknown, context: unknown) => {
            queryClient.invalidateQueries("searchFilters");
            opt?.onSuccess?.(data, variables, context);
        }
    })
};

export const useUpdateSearchFilter = (opt?: MutOpt<any>) => {
    const queryClient = useQueryClient();

    return useMutation<any, ApiServiceErr, { id: string, data: CreateSearchFilter }>(async ({ id, data }) => {
        const body = {
            ...data,
            isActive: Boolean(data.isActive)
        };

        const response = await api.put(`/admin/search-filter/${id}`, body);
        return response.data;
    }, {
        ...opt,
        onSuccess: (data: any, variables: {
            id: string;
            data: CreateSearchFilter;
        }, context: unknown) => {
            queryClient.invalidateQueries("searchFilters");
            opt?.onSuccess?.(data, variables, context);
        }
    });
}

export const useDeleteSearchFilter = (opt?: MutOpt<any>) => {
    const queryClient = useQueryClient();

    return useMutation<any, ApiServiceErr, string>(async (id) => {

        const response = await api.delete(`/admin/search-filter/${id}`);
        return response;
    }, {
        ...opt,
        onSuccess: (data: any, id: string, context: unknown) => {
            queryClient.invalidateQueries("searchFilters");
            opt?.onSuccess?.(data, id, context);
        }
    });
}