import React from 'react';
import { Select as AntSelect, SelectProps } from 'antd';
import styled from 'styled-components';

const SelectWrapper = styled.div``;

interface OnChangeHandler {
  (e: any): void;
}

interface MySelectProps extends SelectProps {
  onChange?: OnChangeHandler;
}

const Select = ({
  children,
  onChange,
  ...rest
}: MySelectProps) => {

  const handleOnChange = (value: string[]) => {
    console.log(`selected ${value}`);
    onChange?.(value);
  };

  return (
    <SelectWrapper>
      <AntSelect onChange={handleOnChange} {...rest}>
        {children}
      </AntSelect>
    </SelectWrapper>
  );
};

export default Select;
