
export interface Category {
    readonly id: string;
    readonly name: string;
    readonly type: CategoryType;
    readonly position: number;
}

export enum CategoryType {
    All = 'all',
    Admin = 'admin',
    Customer = 'customer',
}

export const CategoryTypeOptions = [
    {
        text: 'Admin',
        value: CategoryType.Admin,
    },
    {
        text: 'Customer',
        value: CategoryType.Customer,
    },
]