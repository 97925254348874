import { Button, Space, Table } from 'antd';
import type { TableProps } from 'antd';
import { FC, useEffect, useState } from 'react';
import { CategoryTypeOptions } from '../../dto/Category.dto';
import {
  WatchFaceType,
  WatchFaceTypeOptions,
  WFStatus,
  WFStatusOptions,
} from '../../dto/WatchFace.dto';
import { useApproveWatchFace, useGetWatchFacesWithReports } from '../../services/api/reports';
import ColumnImage from './ColumnImage';
import Expandable from './Expandable';
import dayjs from 'dayjs';
import { SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
interface DataType {
  key: string;
  image: { src: string; alt: string; type: 'video' | 'img', depthUrl: string | null };
  name: string;
  type: string;
  categoryType: string;
  isFree: boolean;
  isApproved: boolean;
  category: string;
  status: WFStatus;
  tags: string[];

  installsCount: number;
  likesCount: number;
  reportsCount: number;
  lastReportDate: any;
  reports: any[];

  styles: any;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: SorterResult<any>['field'];
  sortOrder?: SorterResult<any>['order'];
  filters?: any;
}

const ReportsTable: FC<any> = () => {
  const { Column } = Table;
  const [dataSource, setDataSource] = useState<DataType[]>([]);

  const { mutateAsync: approveWatchFace } = useApproveWatchFace();

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const { data: watchFaces, isLoading } = useGetWatchFacesWithReports({
    page: tableParams.pagination?.current,
    limit: tableParams.pagination?.pageSize,
    ...tableParams?.filters,
    sortOrder: tableParams.sortOrder,
    sortField: tableParams.sortField,
  });

  const handleTableChange: TableProps<DataType>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter) ? undefined : sorter.field,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setDataSource([]);
    }
  };

  useEffect(() => {
    if (!isLoading && watchFaces?.data) {
      setDataSource(
        watchFaces.data?.map((el, index) => {
          const image =
            el.wfType === WatchFaceType.Complication
              ? el.complicationsInfo.previewUrl
              : el.styles.previewUrl;
          const isImage = ['png', 'jpg', 'heic'].includes(
            image.split('.').pop()
          );
          return {
            key: el.id,
            name: el.name,
            type: el.wfType,
            status: el.status,
            category: el.category.name,
            image: {
              src: image,
              alt: el.name,
              type: isImage ? 'img' : 'video',
              depthUrl: el.styles?.depthUrl,
            },
            tags: el.tags,
            isFree: el.isFree,
            isApproved: el.isApproved,
            installsCount: el.installsCount,
            likesCount: el.likesCount,
            reports: el.reports,
            reportsCount: el.reports.length,
            lastReportDate: dayjs(
              Math.max(
                ...el.reports?.map((el) => new Date(el.createdAt).getTime())
              )
            ).format('DD/MM/YYYY'),
            styles: el.styles,
            categoryType: el.category.type,
          };
        })
      );
    }
  }, [isLoading, watchFaces]);

  return (
    <Table
      dataSource={dataSource}
      loading={isLoading}
      pagination={{
        position: ['bottomCenter', 'topCenter'],
      }}
      onChange={handleTableChange}
      expandable={{
        expandRowByClick: true,
        expandedRowRender: (record) => <Expandable reports={record.reports} />,
      }}
    >
      <Column
        title='Image'
        dataIndex='image'
        key='image'
        render={(image) => <ColumnImage {...image} />}
      />
      <Column title='Name' dataIndex='name' key='name' />
      <Column
        title='Type'
        dataIndex='type'
        key='type'
        filters={WatchFaceTypeOptions}
      />
      <Column title='Category' dataIndex='category' key='category' />
      <Column
        title='Category Type'
        dataIndex='categoryType'
        key='categoryType'
        filters={CategoryTypeOptions}
      />
      <Column
        title='Status'
        dataIndex='status'
        key='status'
        filters={WFStatusOptions}
      />
      <Column
        title='Reports'
        dataIndex='reportsCount'
        key='reportsCount'
        sorter={true}
        defaultSortOrder='descend'
      />
      <Column
        title='Last'
        dataIndex='lastReportDate'
        key='lastReportDate'
        sorter={true}
      />
      <Column
        title='Approved'
        dataIndex='isApproved'
        key='isApproved'
        align={'center'}
        render={(isFree: boolean) => {
          return (
            <>{isFree ? <CheckCircleOutlined /> : <CloseCircleOutlined />}</>
          );
        }}
      />
      <Column
        title='Action'
        key='action'
        render={(_: any, record: DataType) => (
          <Space size='middle'>
            <Button
              onClick={(event) => approveWatchFace(record.key)}
            >
              Approve{' '}
            </Button>
          </Space>
        )}
      />
    </Table>
  );
};

export default ReportsTable;
