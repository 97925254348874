import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import SignInPage from './pages/SignInPage';
import HomePage from './pages/HomePage';
import AuthGuard from './guards/AuthGuards';
import UnAuthGuard from './guards/UnAuthGuard';
import { observer } from 'mobx-react-lite';
import CategoriesPage from './pages/CategoriesPage';
import WatchFacesPage from './pages/WatchFacesPage';
import TopWatchFaces from './pages/TopWatchfaces';
import TrendingWatchFaces from './pages/TrendingWatchfaces';
import SearchFiltersPage from './pages/SearchFiltersPage';
import UsersPage from './pages/Users/UsersPage';
import ConfigsPage from './pages/Configs.tsx/ConfigsPage';
import ReportsPage from './pages/ReportsPage';

const App: React.FC = observer(() => {
  return (
    <>
      <Router>
        <Routes>
          <Route
            key='Welcome'
            path='/'
            element={<AuthGuard component={<HomePage />} />}
          />
          <Route
            key='Categories'
            path='/categories'
            element={<AuthGuard component={<CategoriesPage />} />}
          />
          <Route
            key='WatchFaces'
            path='/watchfaces'
            element={<AuthGuard component={<WatchFacesPage />} />}
          />
          <Route
            key='Top WatchFaces'
            path='/top'
            element={<AuthGuard component={<TopWatchFaces />} />}
          />
          <Route
            key='Trending WatchFaces'
            path='/trending'
            element={<AuthGuard component={<TrendingWatchFaces />} />}
          />
          <Route
            key='Search Filters'
            path='/search-filters'
            element={<AuthGuard component={<SearchFiltersPage />} />}
          />
           <Route
            key='Users'
            path='/users'
            element={<AuthGuard component={<UsersPage />} />}
          />
          <Route
            key='Config'
            path='/configs'
            element={<AuthGuard component={<ConfigsPage />} />}
          />
          <Route
            key='Reports'
            path='/reports'
            element={<AuthGuard component={<ReportsPage />} />}
          />
          <Route
            key='Sign In'
            path='/login'
            element={<UnAuthGuard component={<SignInPage />} />}
          />
        </Routes>
      </Router>
    </>
  );
});

export default App;
