import { Layout } from 'antd';
import { FC } from 'react';

const { Content } = Layout;

const AnonymousLayout: FC<any> = ({ children }) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content>{children}</Content>
    </Layout>
  );
};

export default AnonymousLayout;
