export const initials = (firstName: string = "", lastName: string = "") => {
    const first = firstName.length ? firstName.charAt(0).toUpperCase() : "";
    const second = lastName.length ? firstName.charAt(0).toUpperCase() : "";

    return first + second;
}

export function getKeyByValue(T: any, value: string) {
    const indexOfS = Object.values(T).indexOf(value as unknown as typeof T);
    const key = Object.keys(T)[indexOfS];

    return key;
}