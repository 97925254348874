
export enum UserType {
    Admin = 'admin',
    Customer = 'customer',
  }
  
  export enum UserStatus {
    Active = 'active',
    Blocked = 'blocked'
  }

export interface User {
    readonly id: string;
    readonly firebaseId: string;
    readonly username: string;
    readonly type: UserType;
    readonly status: UserStatus;
    readonly email: string;
    readonly bio: string;
    readonly twitter: string;
    readonly instagram: string;
    readonly facebook: string;
    readonly dribble: string;
    readonly snapchat: string;
    readonly tags: string[];
    readonly avatarId: string;
    readonly avatarUrl: string;
    
    readonly createdAt: string;
    readonly updatedAt: string;
}

