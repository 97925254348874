import { FC } from 'react';
import { Input as AntInput, InputProps } from 'antd';
import styled from 'styled-components';

interface OnChangeHandler {
  (e: any): void;
}

interface MyInputProps extends InputProps {
  value?: number;
  onChange?: OnChangeHandler;
}

const InputWrapper = styled.div``;

const TextInput: FC<MyInputProps> = ({
  value,
  onChange = () => {},
  ...rest
}: MyInputProps) => {
  const handleOnChange = (value: any) => {
    onChange?.(value);
  };

  return (
    <InputWrapper>
      <AntInput value={value} onChange={handleOnChange} {...rest}/>
    </InputWrapper>
  );
};

export default TextInput;
