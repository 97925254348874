import { BackgroundColorType, ComplicationSlotType, ComplicationSystemSlotType, CustomColorType, WFComplicationType, WFFaceStyle, WFInformationType } from "../dto/WatchFace.dto"

function getBackgroundColors(type: WFComplicationType): Array<BackgroundColorType> {
    switch (type) {
        case WFComplicationType.California:
            return [
                BackgroundColorType.WhiteCalifornia,
                BackgroundColorType.BlackCalifornia,
                BackgroundColorType.Rainbow,
                BackgroundColorType.Ceramic,
                BackgroundColorType.Gold,
                BackgroundColorType.NavyBlue,
                BackgroundColorType.Ivory,
                BackgroundColorType.PalePink,
                BackgroundColorType.Evergreen,
                BackgroundColorType.Mango,
                BackgroundColorType.Slate,
                BackgroundColorType.Watermelon,
                BackgroundColorType.Red,
                BackgroundColorType.Orange,
                BackgroundColorType.Green,
                BackgroundColorType.LightPink
            ]

        case WFComplicationType.Infograph:
            return [
                BackgroundColorType.WhiteInfograph,
                BackgroundColorType.BlackInfograph
            ]

        case WFComplicationType.Meridian:
            return [
                BackgroundColorType.WhiteMeridian,
                BackgroundColorType.BlackMeridian
            ]
        default:
            return [];
    }
}

function getCustomColors(type: WFComplicationType): Array<CustomColorType> {
    switch (type) {
        case WFComplicationType.ModularCompact:
        case WFComplicationType.XLarge:
        case WFComplicationType.InfographModularColored:
        case WFComplicationType.ModularCompactColored:
        case WFComplicationType.XLargeColored:
            return Object.values(CustomColorType).filter(el => el !== CustomColorType.SeasonWhite) as Array<CustomColorType>;

        case WFComplicationType.InfographModular:
            return Object.values(CustomColorType) as Array<CustomColorType>

        default:
            return [];
    }
}


function getSlots(type: WFComplicationType, complicationsInfo: { faceStyle: WFFaceStyle }): Array<ComplicationSlotType> {
    switch (type) {
        case WFComplicationType.Meridian:
            return [
                ComplicationSlotType.SubdialTop,
                ComplicationSlotType.SubdialLeft,
                ComplicationSlotType.SubdialRight,
                ComplicationSlotType.SubdialBottom
            ];

        case WFComplicationType.Infograph:
            return [
                ComplicationSlotType.Bezel,
                ComplicationSlotType.Slot1,
                ComplicationSlotType.Slot2,
                ComplicationSlotType.Slot3,
            ];

        case WFComplicationType.InfographModular:
        case WFComplicationType.InfographModularColored:
            return [
                ComplicationSlotType.TopLeft,
                ComplicationSlotType.Center,
                ComplicationSlotType.BottomLeft,
                ComplicationSlotType.BottomCenter,
                ComplicationSlotType.BottomRight
            ];

        case WFComplicationType.ModularCompact:
        case WFComplicationType.ModularCompactColored:
            return [
                ComplicationSlotType.Top,
                ComplicationSlotType.Center,
                ComplicationSlotType.Bottom,
            ];

        case WFComplicationType.XLarge:
        case WFComplicationType.XLargeColored:
            return [
                ComplicationSlotType.Center
            ];

        case WFComplicationType.ModularDuo:
            return [
                ComplicationSlotType.TopLeft,
                ComplicationSlotType.Center,
                ComplicationSlotType.Bottom,
            ];

        case WFComplicationType.California:
            if (WFFaceStyle.Fullscreen === complicationsInfo.faceStyle) {
                return [ComplicationSlotType.SubdialTop, ComplicationSlotType.SubdialBottom];
            } else {
                return [];
            }


        default:
            return [];
    }

}

function getSystemSlots(type: WFComplicationType, complicationsInfo: { faceStyle: WFFaceStyle }): Array<ComplicationSlotType> {
    switch (type) {
        case WFComplicationType.California:
            if (WFFaceStyle.Fullscreen === complicationsInfo.faceStyle) {
                return [ComplicationSlotType.SubdialTop];
            } else {
                return [];
            }


        default:
            return [];
    }

}

function getSystemSlot(slot: ComplicationSlotType): ComplicationSystemSlotType {
    const tempEnum = {
        [ComplicationSlotType.SubdialTop]: ComplicationSystemSlotType.SystemSubdialTop,
        [ComplicationSlotType.SubdialLeft]: ComplicationSystemSlotType.SystemSubdialLeft,
        [ComplicationSlotType.SubdialRight]: ComplicationSystemSlotType.SystemSubdialRight,
        [ComplicationSlotType.SubdialBottom]: ComplicationSystemSlotType.SystemSubdialBottom,
        [ComplicationSlotType.Bezel]: ComplicationSystemSlotType.SystemBezel,
        [ComplicationSlotType.Slot1]: ComplicationSystemSlotType.SystemSlot1,
        [ComplicationSlotType.Slot2]: ComplicationSystemSlotType.SystemSlot2,
        [ComplicationSlotType.Slot3]: ComplicationSystemSlotType.SystemSlot3,
        [ComplicationSlotType.TopLeft]: ComplicationSystemSlotType.SystemTopLeft,
        [ComplicationSlotType.BottomLeft]: ComplicationSystemSlotType.SystemBottomLeft,
        [ComplicationSlotType.BottomCenter]: ComplicationSystemSlotType.SystemBottomCenter,
        [ComplicationSlotType.BottomRight]: ComplicationSystemSlotType.SystemBottomRight,
        [ComplicationSlotType.Top]: ComplicationSystemSlotType.SystemTop,
        [ComplicationSlotType.Center]: ComplicationSystemSlotType.SystemCenter,
        [ComplicationSlotType.Bottom]: ComplicationSystemSlotType.SystemBottom,
    }
    return tempEnum[slot]
}

function getSystemSlotsValues(type: WFComplicationType, slot: ComplicationSlotType): Array<WFInformationType> {
    switch (type) {
        case WFComplicationType.California:
            if (slot === ComplicationSlotType.SubdialTop) {
                return [WFInformationType.Date]
            } else {
                return [];
            }
        default:
            return [];
    }
}

export { getBackgroundColors, getCustomColors, getSlots, getSystemSlotsValues, getSystemSlots, getSystemSlot }