import { Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import Button from '../components/Forms/Controls/Button';
import DefaultLayout from '../components/Layouts/DefaultLayout';
import Modal from '../components/Modal';
import CreateCategoryModal from '../components/Modals/CreateCategoryModal';
import { Category, CategoryType } from '../dto/Category.dto';
import { useDeleteCategory, useGetCategories } from '../services/api/category';

interface DataType {
  key: string;
  name: string;
  type: CategoryType;
  position: number;
}

const CategoriesPage: React.FC = () => {
  const { Column } = Table;

  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [type, setType] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>();

  const [dataSource, setDataSource] = useState<DataType[]>([]);

  const { data: categories, isLoading } = useGetCategories({
    limit: limit,
    page: page,
    type: type,
  });

  const { mutateAsync: deleteCategory } = useDeleteCategory();

  const handleAddCategories = () => {
    setSelectedCategory(null);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (categories?.data?.length) {
      setTotal(categories.resultsCount);
      setDataSource(
        categories.data.map((el, index) => {
          return {
            key: el.id,
            name: el.name,
            type: el.type,
            position: el.position,
          };
        })
      );
    }
  }, [categories]);

  return (
    <>
      <DefaultLayout>
        <main>
          <div>
            <h2 className='text-black'>Categories Page</h2>
            <Button onClick={handleAddCategories}>Add</Button>
          </div>

          <div>
            <Table
              dataSource={dataSource}
              loading={isLoading}
              pagination={{
                current: page,
                total: total,
                pageSize: limit,
                position: ['bottomCenter', 'topCenter'],
              }}
              onChange={(page, filter) => {
                setType(filter.type?.[0] ? filter.type[0] : undefined);
                page.current && setPage(page.current);
                page.pageSize && setLimit(page.pageSize);
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    setSelectedCategory({
                      ...record,
                      id: record.key,
                    });
                    setIsModalOpen(true);
                  },
                };
              }}
            >
              <Column title='Id' dataIndex='key' key='key' width={'30%'} />
              <Column
                title='Position'
                dataIndex='position'
                key='position'
                width={'10%'}
              />
              <Column title='Name' dataIndex='name' key='name' width={'30%'} />
              <Column
                title='Type'
                dataIndex='type'
                key='type'
                width={'30%'}
                filters={[
                  {
                    text: 'Admin',
                    value: CategoryType.Admin,
                  },
                  {
                    text: 'Customer',
                    value: CategoryType.Customer,
                  },
                ]}
                filterMultiple={false}
              />
              <Column
                title='Action'
                key='action'
                render={(_: any, record: DataType) => (
                  <Space size='middle'>
                    <Button
                      onClick={(event) => {
                        event.stopPropagation();
                        const category = categories?.data.find(
                          (c) => c.id === record.key
                        );
                        setSelectedCategory(category);
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      Delete{' '}
                    </Button>
                  </Space>
                )}
              />
            </Table>
          </div>
        </main>
      </DefaultLayout>
      <CreateCategoryModal
        setIsOpen={setIsModalOpen}
        isOpen={isModalOpen}
        category={selectedCategory}
      />
      <Modal
        title='Delete Category'
        content={'Are you sure you want to delete this Category?'}
        open={isDeleteModalOpen}
        onCancel={() => {
          setIsDeleteModalOpen(false);
        }}
        cancelText={'No, keep the category'}
        onOk={async () => {
          selectedCategory?.id && (await deleteCategory(selectedCategory.id));
          setIsDeleteModalOpen(false);
          setSelectedCategory(null);
        }}
        okText={'Yes, delete it'}
      />
    </>
  );
};

export default CategoriesPage;
