import { FC } from 'react';
import { initials } from '../utils/helpers';

const Avatar: FC<any> = ({ src, userName }) => {
 
  const firstName = userName.split(' ')[0];
  const lastName = userName.split(' ')[1];

  if (!src)
    return (
      <div className='w-8 h-8 rounded-full bg-slate-400 flex items-center justify-center text-sm text-slate-100'>
        {initials(firstName, lastName)}
      </div>
    );

  return (
    <div className='w-8 h-8 rounded-full bg-slate-400 flex items-center justify-center text-sm text-slate-100'>
      <img
        src={src}
        alt={userName}
        className={'w-8 h-8 object-cover rounded-full'}
      />
    </div>
  );
};

export default Avatar;
