import {
  AppstoreOutlined,
  ArrowLeftOutlined,
  TeamOutlined,
  RocketOutlined,
  StarOutlined,
  TabletOutlined,
  ReadOutlined,
  ContainerOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const SideMenu: FC<any> = ({ onClose }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedKeys, setSelectedKeys] = useState('/');

  useEffect(() => {
    const pathName = location.pathname;
    setSelectedKeys(pathName);
  }, [location.pathname]);

  return (
    <div className='SideMenu flex flex-col h-full justify-between overflow-auto'>
      <Menu
        className='SideMenuVertical overflow-auto max-h-[calc(100vh-50px)] bg-[#81D4FA]'
        mode='vertical'
        onClick={(item) => {
          navigate(item.key);
        }}
        selectedKeys={[selectedKeys]}
        items={[
          {
            label: 'Categories',
            key: '/categories',
            icon: <AppstoreOutlined />,
          },
          {
            label: 'WatchFaces',
            key: '/watchfaces',
            icon: <TabletOutlined />,
          },
          {
            label: 'Top',
            key: '/top',
            icon: <StarOutlined />,
          },
          {
            label: 'Trending',
            key: '/trending',
            icon: <RocketOutlined />,
          },
          {
            label: 'Search Filters',
            key: '/search-filters',
            icon: <AppstoreOutlined />,
          },
          {
            label: 'Users',
            key: '/users',
            icon: <TeamOutlined />,
          },
          {
            label: 'Configs',
            key: '/configs',
            icon: <ReadOutlined />,
          },
          {
            label: 'Reports',
            key: '/reports',
            icon: <ContainerOutlined />,
          },
          
        ]}
      ></Menu>
      <div className='h-[50px]'>
        <div
          className='flex items-center h-full justify-center cursor-pointer'
          onClick={onClose}
        >
          <ArrowLeftOutlined />
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
