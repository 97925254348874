import React, { FC, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { message, Modal, Upload } from 'antd';
import type { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import type { RcFile, UploadProps } from 'antd/es/upload';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const UploadPreview: FC<any> = ({
  supportType,
  onChange,
  defaultFileList = [],
  ...restProps
}) => {

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const [fileList, setFileList] = useState<UploadFile[]>([...defaultFileList]);

  const beforeUpload = (file: RcFile) => {
    const isSupportType = supportType.includes(file.type);
    if (!isSupportType) {
      message.error('Unsupported file type');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isSupportType && isLt2M;
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/'))
    );
  };

  const handleChange: UploadProps['onChange'] = async (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === 'uploading') {
      info.file.status = 'done';
      setFileList(info.fileList);
      onChange(info.file.originFileObj);
    }

    if (info.file.status === 'removed') {
      setFileList([]);
      onChange(null);
    }
  };

  useEffect(() => {
    setFileList(defaultFileList);
  }, [defaultFileList]);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Upload
        {...restProps}
        name='preview'
        listType='picture-card'
        fileList={fileList}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        onPreview={handlePreview}
        maxCount={1}
        // {...restProps}
      >
        {fileList.length === 1 ? null : uploadButton}
        {/* {uploadButton} */}
        {/* <div>{fileList.length}. length</div> */}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export default UploadPreview;
